import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { Component } from '../../../../../lib/web/components/component';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ApiService } from '../../../services/api.service';
import { ValidationService } from '../../../services/validation.service';

@Component({
    selector: '.m-enter-pin'
})
export class EnterPinComponent extends ModalComponent<HTMLInputElement> {

    @ChildrenRef(HTMLInputElement, '.a-input-pin__code')
    private _inputsElement: HTMLInputElement[];

    public constructor(node: HTMLInputElement,
        private _apiService: ApiService,
        private _validationService: ValidationService,
    ) {
        super(node);
    }

    public onInit(): void {
        super.onInit();
    }

    public async open(): Promise<any> {
        this.loadData();
        return super.open();
    }

    private async loadData(): Promise<void> {
        this.setFormData({ parentalPin: null });

        this.isLoadingData = true;
        this.clearInputs();
        this.focus();
        this.isLoadingData = false;
    }

    @EventListener('input', 'input')
    public onChange(): void {
        const data = this.getFormData('.m-enter-pin__form');
        if (data.parentalPin?.length === 4 && this.validate()) {
            this.submit();
        }
    }

    private async submit(): Promise<void> {
        try {
            const data: any = this.getFormData('.m-enter-pin__form');
            await this._apiService.post('/api/v1/user/validate-parental-pin', {
                body: { parentalPin: data.parentalPin }
            });
            this.close(data.parentalPin);
        }
        catch (e: any) {
            if (e.statusCode == 400 || e.statusCode == 403) {
                this._validationService.showErrors('.m-enter-pin__form', { parentalPin: [e.data?.message] });
            }
            else {
                this.showUnexpectedError(e);
            }
        }
    }

    public validate(): boolean {
        const data: any = this.getFormData('.m-enter-pin__form');
        const errors: DictionaryObject<string[]> = this._validationService.validateParentalPin(data, data, 4);
        this._validationService.showErrors('.m-enter-pin__form', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        const errors: DictionaryObject<string[]> = this._validationService.validateParentalPin(field, this.getFormData('.m-enter-pin__form'), 4);
        this._validationService.showErrors('.m-enter-pin__form', errors);
    }

    private clearInputs() {
        this._inputsElement.forEach(i => i.value = '');
    }
}