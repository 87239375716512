import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';

@Component({
    selector: '.a-switch-box'
})
export class SwitchBoxComponent extends ComponentBase<HTMLInputElement> {

    @ChildRef('input')
    private _inputElement: HTMLInputElement = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(): void {
        (this._node as any).name = this._inputElement.name;
    }

    @EventListener('blur', 'input')
    public onBlur(): void {
        this.dispatchCustomEvent('blur');
    }

    @EventListener('change', 'input')
    public onChange(): void {        
        this.dispatchCustomEvent('change', this);
    }

    @EventListener('input', 'input')
    public onInput(): void {        
        this.dispatchCustomEvent('input', this);
    }

    public focus(): void {
        this._inputElement.focus();
    }

    public get value(): boolean {
        return this._inputElement.checked;
    }

    public set value(value: boolean) {
        this._inputElement.checked = value;
    }
}