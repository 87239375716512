import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';
import { ValidationService } from '../../../services/validation.service';

@Component({
    selector: '.m-change-profile'
})
export class ChangeProfileComponent extends ModalComponent<HTMLInputElement> {

    public constructor(node: HTMLInputElement,
        private _apiService: ApiService,
        private _validationService: ValidationService,
        private _sessionService: SessionService) {
        super(node);
    }    

    public onInit(): void {
        super.onInit();
        this.registerFormFieldInput('.m-change-profile__form', field => this.validateField(field));
    }

    public async open(): Promise<any> {
        this.loadData();        
        return super.open();
    }

    private async loadData(): Promise<void> {
        this.setFormData({ name: null, surname1: null, surname2: null, genre: null, birthDate: null });

        this.isLoadingData = true;
        const user: any = await this._apiService.get(`/api/v1/user/profile-data`);
        this.setFormData(user);
        this.isLoadingData = false;

        this.focus('#txtName');
        this.disable('.m-change-profile__save');
    }
    
    @EventListener( 'input', 'input')
    public onChange(): void {
        this.enable('.m-change-profile__save');
    }

    @ClickListener('.m-change-profile__cancel')
    public onCancel(): void {
        this.close();
    }

    @ClickListener('.m-change-profile__save')
    public onSave(): void {
        if (this.validate()) {
            this.submit();
        }
    }

    private async submit(): Promise<void> {
        try {
            this.disable('.m-change-profile__save');
            const data: any = this.getFormData('.m-change-profile__form');
            const user: any = await this._apiService.put('/api/v1/user/profile-data', { 
                body: data                 
            });
            this._sessionService.setGender(data.gender);
            this.close(user);

        }
        catch (e: any) {
            if (e.statusCode == 400) {
                this._validationService.showErrors('.m-change-profile__form', JSON.parse(e.data?.message));
            }
            else {
                this.showUnexpectedError(e);
            }
        }
        finally {
            this.enable('.m-change-profile__save');
        }
    }

    public validate(): boolean {
        const data: any = this.getFormData('.m-change-profile__form')
        const errors: DictionaryObject<string[]> = this._validationService.validateProfileData(data, data);
        this._validationService.showErrors('.m-change-profile__form', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        const errors: DictionaryObject<string[]> = this._validationService.validateProfileData(field, this.getFormData('.m-change-profile__form'));
        this._validationService.showErrors('.m-change-profile__form', errors);
    }
}