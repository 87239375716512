import { Injectable } from "../../../lib/web/reflection/injectable";

@Injectable({ type: 'singleton' })
export class DataEventService {

    public constructor() {
        window.dataLayer = window.dataLayer || [];
    }   

    public completeRegistration(userId: string, loginType: 'email'): void {
        this.addDataEvent('complete_registration', 
        {
            login_type: loginType,
            user_id_login: userId
        });
    }

    public loginTry(): void {
        this.addDataEvent('login_try');
    }

    public loginSend(loginType: 'email'): void {
        this.addDataEvent('login_send', 
        {
            login_type: loginType
        });
    }

    public login(userId: string, loginType: 'email'): void {
        this.addDataEvent('login', 
        {            
            login_type: loginType,
            user_id_login: userId
        });
    }

    public loginFail(loginType: 'email'): void {
        this.addDataEvent('login_fail', 
        {
            login_type: loginType
        });
    }

    public textTrackEnabled(id: string, name: string, disciplines: string[], typology: string, language: string): void {
        this.addDataEvent('video', 
        {
            video_action: 'enable subtitles',
            video_id: id,
            video_name: name,
            video_theme: (disciplines || []).join(','),
            video_type: typology,
            language_subtitle: language
        });
    }

    public share(action: string, id: string, name: string, disciplines: string, typology: string, socialNetwork: string, location: string): void {
        if (action == 'share_video') {
            this.addDataEvent('video', 
            {
                video_action: action,
                video_id: id,
                video_name: name,
                video_theme: disciplines,
                video_type: typology,
                social_network: socialNetwork,
                location
            });
        }
        else if (action == 'share_collection') {
            this.addDataEvent('collection', 
            {
                collection_action: action,
                collection_id: id,
                collection_landing_name: name,
                collection_theme: disciplines,
                collection_type: typology,
                social_network: socialNetwork,
                location
            });
        }
        else if (action == 'share_partner_landing') {
            this.addDataEvent('partner_landing', 
            {
                partner_landing_action: action,
                partner_landing_id: id,
                partner_landing_name: name,
                social_network: socialNetwork,
                location
            });
        }
    }

    public addFavorite(type: string, id: string, name: string, disciplines: string, typology: string): void {
        if (type == 'video') {
            this.addDataEvent('video',
            {
                video_action: 'favorite',
                video_id: id,
                video_name: name,
                video_theme: disciplines,
                video_type: typology
            });
        }
        else if (type == 'collection') {
            this.addDataEvent('collection', 
            {
                collection_action: 'favorite',
                collection_id: id,
                collection_landing_name: name,
                collection_theme: disciplines,
                collection_type: typology
            });
        }
        else if (type == 'partner_landing') {
            this.addDataEvent('partner_landing', 
            {
                partner_landing_action: 'favorite',
                partner_landing_id: id,
                partner_landing_name: name
            });
        }
    }

    public shareStreaming(id: string, name: string, disciplines: string[], typology: string, location: string, via: string): void {
        this.addDataEvent('video', 
        {
            video_action: 'share_streaming',
            video_id: id,
            video_name: name,
            video_theme: (disciplines || []).join(','),
            video_type: typology,
            location,
            via,
        });
    }

    public page(userId: string, type: string): void {
        window.dataLayer.push({ 
            user_login: userId ? 1: 0,
            content_group: type,
            user_id_login: userId || ''
        }); 
    }

    public openFragment(id: string, name: string, location: string): void {
        this.addDataEvent('open_fragment',  
        {
            video_id: id, 
            video_name: name, 
            location 
        });
    }

    public selectFragment(id: string, name: string, location: string, fragment: string): void {
        this.addDataEvent('select_fragment', 
        {
            video_id: id,
            video_name: name,
            location,
            fragment,
        });
    }

    public selectFilter(name: string, type: string, value: number | number[]): void {
        this.addDataEvent('select_filter', 
        {
            filter_name: name,
            filter_type: type,
            filter_value: value,
        });
    }

    public clickMenu(url: string, name: string, text: string, type: string, pageName: string, pageUrl: string): void {
        this.addDataEvent('menu',
        {
            menu_action: 'click_menu',
            menu_url: url,
            menu_name: name,
            menu_text: text,
            menu_type: type,
            menu_page: pageName,
            menu_page_url: pageUrl
        });
    }

    private addDataEvent(name: string, data?: any): void {
        window.dataLayer.push({ 
            event: name,
            ...(data || {})
        }); 
        logger.debug(`📊 event '${name}' added`, Array.from(window.dataLayer as any).last());
    }
}