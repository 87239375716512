import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';

@Component({
    selector: '.a-volume-selector'
})
export class VolumneSelectorComponent extends ComponentBase<HTMLInputElement> {
  
    @ChildRef('input')
    private _inputElement: HTMLInputElement = null;
    
    private _hideTimeout: NodeJS.Timeout = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        this.setValue();
    }

    @EventListener('input', 'input')
    public onInput(): void {
        this.setValue();
        this.dispatchCustomEvent('input');
        this.setHideTimeout();
    }

    public show(): void {
        this.addClass('a-volume-selector--show');
        this.setHideTimeout();
    }

    public hide(): void {
        this.removeClass('a-volume-selector--show');
        if (this._hideTimeout) {
            clearTimeout(this._hideTimeout);
        }
    }

    private setHideTimeout(): void {
        if (this._hideTimeout) {
            clearTimeout(this._hideTimeout);
            this._hideTimeout = null;
        }
        this._hideTimeout = setTimeout(() => {
            this.hide();
        }, 5000)
    }

    public get value(): number {
        return parseFloat(this._inputElement.value);
    }

    public set value(value: number) {
        this._inputElement.value = (value || 0).toString();
        this.setValue();
    }

    public get min(): number {
        return parseFloat(this._inputElement.min);
    }

    public set min(value: number) {
        this._inputElement.min = (value || 0).toString();
    }

    public get max(): number {
        return parseFloat(this._inputElement.max);
    }

    public set max(value: number) {
        this._inputElement.max = (value || 0).toString();
    }

    private setValue(): void {
        const current: number = ((this.value || 0) / this.max);
        this._node.style.setProperty('--volume-selector-current', `${ current * 100 }%`);
    }

    public dispose(): void {
        this.hide();
        super.dispose();
    }
}