import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { SessionService } from '../../../services/session.service';
import { AppMenuComponent } from '../../molecules/app-menu/app-menu.component';
import { MobileMenuComponent } from '../../molecules/mobile-menu/mobile-menu.component';
import { ProfileMenuComponent } from '../../molecules/profile-menu/profile-menu.component';

@Component({
    selector: '.o-header'
})
export class HeaderComponent extends ComponentBase<HTMLElement> {
   
    private _mobileMenu: MobileMenuComponent = null; 

    @ChildRef()
    public _profileMenu: ProfileMenuComponent = null;       
    @ChildRef()
    public _appMenu: AppMenuComponent = null;     
    @ChildRef('.o-header__profile-image')
    public _userPictureElement: HTMLImageElement = null;

    public constructor(node: HTMLElement, private _sessionService: SessionService) {
        super(node);

        this.addCustomWindowEventListener('user-picture-changed', () => this.setUserPictureUrl());
    }

    public onInit(): void {
        this._mobileMenu = document.querySelector<HTMLElement>('.m-mobile-menu').componentRef.ref as MobileMenuComponent;
        this.addClass(this._sessionService.isSigned ? 'o-header--signed': 'o-header--unsigned');
        this.setUserPictureUrl();
        this.addCustomEventListener('toggle', e => {
            const { show } = e;
            this.onToogleAppMenu(show);
        }, '.m-app-menu');
        setTimeout(() => {
            this._appMenu.checkOverflow();
            this._node.classList.add('o-header--show');
        }, 200);  
        this.setSelected();
        this.checkWebInProgress();     
    }

    @ClickListener('.o-header__mobile-menu')
    public onMobileMenu(e: MouseEvent): void {
        this._mobileMenu.toggle(null, e);
    }
    
    @ClickListener('.o-header__profile')
    public async onProfile(e: MouseEvent): Promise<void> {
        const arrowElement: HTMLElement = (e.target as HTMLElement).closest('.o-header__profile').querySelector('.u-arrow');
        arrowElement.classList.remove('u-arrow--down');
        arrowElement.classList.add('u-arrow--up');
        await this._profileMenu.toggle(null, e);
        arrowElement.classList.remove('u-arrow--up');
        arrowElement.classList.add('u-arrow--down');
    }

    private onToogleAppMenu(show: boolean): void {
        this.addOrRemoveClass(!show, 'o-header--mobile');
    }

    private setSelected(): void {
        const { pathname } = location;
        switch (pathname) {
            case '/search':
                this.addClass('o-header__search--selected', '.o-header__search');
                break;
        }
    }

    private checkWebInProgress(): void {
        if (CONFIG.IN_PROGRESS_UNTIL && new Date(Date.parse(CONFIG.IN_PROGRESS_UNTIL)) > new Date() && ['/', '/home'].includes(location.pathname)) {
            document.body.classList.add('u-in-progress');
        }
        else {
            document.body.classList.remove('u-in-progress');
        }
    }

    private setUserPictureUrl(): void {        
        if (this._sessionService.isSigned && this._sessionService.get()?.user?.pictureUrl) {
            this._userPictureElement.src = `${CONFIG.STORAGE_URL}/${this._sessionService.get().user.pictureUrl}?timestamp=${Date.now()}`;
            this.addClass('u-hidden-important', '.o-header__profile-icon');
            this.removeClass('u-hidden-important', '.o-header__profile-image');
        }
        else {
            this.addClass('u-hidden-important', '.o-header__profile-image');
            this.removeClass('u-hidden-important', '.o-header__profile-icon');
        }
    }
}