import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { SessionStorageService } from '../../../../../lib/web/services/session-storage.service';
import { ToastService } from '../../../../../lib/web/services/toast.service';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.o-register-block'
})
export class RegisterBlockComponent extends ComponentBase<HTMLElement> {

    @Input('messageAfterRegisterOrLogin')
    private _messageAfterRegisterOrLogin: string = null;
    
    public constructor(node: HTMLElement, 
        private _apiService: ApiService, 
        private _toastService: ToastService,
        private _sessionService: SessionService,
        private _sessionStorageService: SessionStorageService) {

        super(node);  
    }

    public onInit(): void {
        super.onInit();
        setTimeout(() => {
            this.setRegisterData();
        }, 1000);
        setTimeout(() => {
            if (!this._sessionStorageService.get('messageAfterRegisterOrLogin')) {
                this.setRegisterData();
            }
        }, 3000);
    }

    private setRegisterData(): void {
        if (this._messageAfterRegisterOrLogin) {
            this._sessionStorageService.set('messageAfterRegisterOrLogin', this._messageAfterRegisterOrLogin);
        }
        if (this._sessionService.isSigned)  {
            this.addClass('u-hidden');
            this.addTag();
            this._sessionService.showMessageAfterRegisterOrLogin();
        }
        else {
            this._sessionStorageService.set('registerFrom', location.pathname);
        }
    }

    private async addTag() {
        try {
            await this._apiService.post('/api/v1/user/add-tags', {
                body: [{
                    name: 'registerFrom',
                    value: location.pathname
                }]
            });
        }
        catch (e: any) {
            console.log(e);
        }
    }
}
