import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.o-button-block'
})
export class ButtonBlockComponent extends ComponentBase<HTMLAnchorElement> {

    public constructor(node: HTMLAnchorElement, private _sessionService: SessionService) {
        super(node);  
    }

    public onInit(): void {
        super.onInit();
        if (this._sessionService.isSigned && this._node.href?.includes('/u/register'))  {
            this.addClass('u-hidden-important');
        }
    }
}
