import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { ApiService } from '../../../services/api.service';
import { GridComponent } from '../../organisms/grid/grid.component';

@Component({
    selector: '.p-discipline'
})
export class DisciplineComponent extends ComponentBase<HTMLElement> {

    @Input('id')
    private _id: number = 0;

    @ChildRef()
    private _grid: GridComponent = null;

    public constructor(node: HTMLElement, private _apiService: ApiService) {
        super(node);       
    }     

    public onInit(): void {
        super.onInit();
        this._grid.addCustomEventListener('load-page', (data: { page: number }) => this.loadPage(data));
    }

    private async loadPage(data: { page: number }): Promise<void> {
        const { page } = data;
        const response: { items: any[], pageInfo: { currentPage: number, totalPages: number } } = await this._apiService.get('/api/v1/discipline/content', { 
            query: {
                id: this._id,
                page,                                
            }
        });            
        this._grid.setPageResponse(response.items, response.pageInfo);
    }
}