import { Str } from '../../lib/web/core/str';
import { Num } from '../../lib/web/core/num';
import { ArrayUtil } from '../../lib/web/core/array-util';
import { Injector } from '../../lib/web/reflection/injector';
import { DateTime } from '../../lib/web/core/date-time';
import { BootstrapService } from './services/bootstrap.service';
import { ClientLogService } from '../../lib/web/services/client-log.service';

window.logger = Injector.get(ClientLogService);

Str.addExtensionMethods();
Num.addExtensionMethods();
DateTime.addExtensionMethods()
ArrayUtil.addExtensionMethods();

function start(): void {
    const bootstrapService: BootstrapService = Injector.get(BootstrapService);
    if (bootstrapService.check()) {
        window.addEventListener('load', () => {        
            bootstrapService.start('load');   
        });    
    }
}

start();