import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { ScrollService } from '../../../../../lib/web/services/scroll.service';
import { EjsService } from '../../../services/ejs.service';

@Component({
    selector: '.o-grid'
})
export class GridComponent extends ComponentBase<HTMLElement> {

    private _loadingMore: boolean = false;
    
    @Input('currentPage')
    private _currentPage: number = null;
    @Input('totalPages')
    private _totalPages: number = null;  
    @Input('format')
    private _format: string = null;  

    @ChildRef('.o-grid__items')
    private _itemsElement: HTMLDivElement;

    public constructor(node: HTMLElement, private _scrollService: ScrollService, private _ejsService: EjsService) {
        super(node);
    }

    public onInit(): void {
        this._scrollService.onScrolledTo(window, 90, () => this.loadMore());
    }

    public async loadMore(): Promise<void> {
        if (!this._loadingMore && this._currentPage < this._totalPages) {
            this._loadingMore = true; 
            this.dispatchCustomEvent('load-page', { page: this._currentPage + 1 });
        }
    }    
    
    public setPageResponse(items: any[], pageInfo: { currentPage: number, totalPages: number }): void {
        const { currentPage, totalPages } = pageInfo;
        if (items && items.length) {
            const html: string = items.map(i => this._ejsService.render('organisms/grid/grid-item', { item: i, format: this._format })).join('');
            this._itemsElement.innerHTML += html;
            this.fragment();
        }
        this._currentPage = currentPage;
        this._totalPages = totalPages;
        setTimeout(() => {
            this._loadingMore = false;
        }, 500);
    }

    public get itemElements(): HTMLDivElement[] {
        return Array.from(this._node.querySelectorAll('.o-grid__item'));
    }

    public setProgress(id: number, data: { current: number, duration: number }): void {        
        const itemElement: HTMLDivElement = this._node.querySelector(`.o-grid__item[data-id='${id}']`);
        if (itemElement) {
            const progressValueElement: HTMLDivElement = itemElement.querySelector('.o-grid__item-progress-value');
            if (progressValueElement) {
                const { current, duration } = data;
                itemElement.classList.add('o-grid__item--with-progress');
                progressValueElement.style.width = `${((current || 0) / (duration || 1)) * 100}%`;
            }
        }
    }

    public reset(): void {
        this._loadingMore = false;
        this._currentPage = 0;
        this._totalPages = 1;
        this.setInnerHTML('', '.o-grid__items');
        this.loadMore();
    }
}