import { Injectable } from "../../../lib/web/reflection/injectable";
import { SessionStorageService } from "../../../lib/web/services/session-storage.service";
import { ApiService } from "./api.service";
import { SessionService } from "./session.service";

interface UserWatchingHistoryData {
    time: number;
    history: UserWatchingHistory[];
}

interface UserWatchingHistory {
    id: number;
    type: 'capsule' | 'collection';
    capsuleId: number;
    current: number;
    duration: number;
    isArchived?: boolean;
    subtitle?: string;
}

@Injectable({ type: 'singleton' })
export class UserWatchingHistoryService {

    public constructor(private _sessionStorageService: SessionStorageService,
        private _apiService: ApiService, 
        private _sessionService: SessionService) {
    }

    public init(): void {
        if (this._sessionService.isSigned) {
            this.load();
        }
        else {
            this.clear();
        }
    }

    private async load(): Promise<void> {
        const watchingHistory: UserWatchingHistoryData = this.get();        
        const { time } = watchingHistory;
        if (!time || new Date(time).addHours(CONFIG.USER_WATCHING_HISTORY_RESET_HOURS) < new Date()) {
            const response: any[] = await this._apiService.get('/api/v1/user/watching');
            this.set({
                time: new Date().getTime(),
                history: response
            });
        }
    }

    private clear(): void {
        this._sessionStorageService.remove('watching-history');
    }

    private get(): UserWatchingHistoryData {
        return this._sessionStorageService.get('watching-history') || { history: [] };
    }

    private set(data: UserWatchingHistoryData): void {
        this._sessionStorageService.set('watching-history', data);
    }

    private delete(id: number, type: 'capsule' | 'collection'): UserWatchingHistoryData {
        const data: UserWatchingHistoryData = this.get();        
        data.history = (data.history || []).filter(w => !(w.id == id && w.type == type));
        this.set(data);
        return data;      
    }

    public isWatching(id: number, type: 'capsule' | 'collection'): boolean {
        let isWatching: boolean = false;
        if (this._sessionService.isSigned) {
            const history: UserWatchingHistory[] = this.get().history || [];            
            isWatching = history.some(w => w.id == id && w.type == type && !w.isArchived && (w.duration - w.current > CONFIG.USER_WATCHING_HISTORY_ARCHIVE_PENDING_TIME));
        }
        return isWatching;
    }

    public getItem(id: number, type: 'capsule' | 'collection'): UserWatchingHistory {
        let item: UserWatchingHistory = null;
        if (this._sessionService.isSigned) {
            const history: UserWatchingHistory[] = this.get().history || [];
            item = history.find(w => w.id == id && w.type == type);
        }
        return item;
    }

    public getCurrentTime(id: number, type: 'capsule' | 'collection'): number {
        if (this.isWatching(id, type)) {
            const item: UserWatchingHistory = this.getItem(id, type);
            return item ? item.isArchived ? 0: item.current: null;
        }
        else {
            return 0;
        }
    }

    public getSubtitle(id: number, type: 'capsule' | 'collection', collectionId?: number): string {
        let subtitle: string = null;
        if (this.isWatching(id, type)) {
            const item: UserWatchingHistory = this.getItem(id, type);
            if (item?.subtitle) {
                subtitle = item.subtitle;
            }            
        }
        if (!subtitle && type == 'capsule' && collectionId) {
            if (this.isWatching(collectionId, 'collection')) {
                subtitle = this.getSubtitle(collectionId, 'collection');
            }
        }
        return subtitle == 'disabled' ? null: subtitle;
    }

    private upsert(userWatchingHistory: UserWatchingHistory, isLastCollectionChapter: boolean): void {
        const { id, type, capsuleId, current, duration, subtitle } = userWatchingHistory;
        const data: UserWatchingHistoryData = this.get();
        
        data.history = (data.history || []).filter(w => !(w.id == id && w.type == type));
        if (capsuleId) {
            data.history = (data.history || []).filter(w => !(w.id == capsuleId && w.type == 'capsule'));
        }

        const isArchived: boolean = (duration - current) < CONFIG.USER_WATCHING_HISTORY_ARCHIVE_PENDING_TIME;
        data.history = [
            ...data.history || [],
            { 
                ...userWatchingHistory,
                isArchived: type == 'collection' ? isArchived && isLastCollectionChapter: isArchived
            }
        ];
        if (capsuleId) {
            data.history = [
                ...data.history,
                {
                    id: capsuleId,
                    type: 'capsule',
                    capsuleId: null,
                    current,
                    duration,
                    isArchived,
                    subtitle
                }
            ];
        }
        this.set(data);
    }

    public archive(id: number, type: 'capsule' | 'collection'): void {
        let data: UserWatchingHistoryData = this.get();        
        const item: any = data.history.find(w => w.id == id && w.type == type);
        if (item) {
            data = this.delete(id, type);            
            item.isArchived = true;
            data.history.push(item);
            this.set(data);
        }
    }

    public async add(data: { capsuleId: number, collectionId: number, isLastCollectionChapter: boolean, currentTime: number, durationTime: number, nextCapsuleId: number, nextCapsuleDurationTime: number, subtitle: string }): Promise<void> {
        const { capsuleId, collectionId, isLastCollectionChapter, currentTime, durationTime, nextCapsuleId, nextCapsuleDurationTime, subtitle } = data;
        this.upsert({
            id: collectionId || capsuleId,
            capsuleId: capsuleId,
            type: collectionId ? 'collection': 'capsule',
            current: currentTime,
            duration: durationTime,
            subtitle
        }, isLastCollectionChapter);
        // let url: string = `/api/v1/user/watching/${capsuleId}/${currentTime}/${durationTime}`;
        // if (collectionId) {
        //     url = `${url}/${collectionId}/${isLastCollectionChapter}/${nextCapsuleId}/${nextCapsuleDurationTime}`;
        // }
        let url: string = `/api/v1/user/watching/${capsuleId}`;
        await this._apiService.post(url,{ 
            body: {
                collectionId,
                isLastCollectionChapter,
                current: currentTime, 
                duration: durationTime, 
                nextCapsuleId, 
                nextCapsuleDuration: nextCapsuleDurationTime, 
                subtitle: subtitle == 'disabled' ? null: subtitle
            }
        });
    }
}