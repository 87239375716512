import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { MouseEnterListener } from '../../../../../lib/web/components/mouse-enter-listener';
import { MouseLeaveListener } from '../../../../../lib/web/components/mouse-leave-listener';
import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Dom } from '../../../../../lib/web/core/dom';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Input } from '../../../../../lib/web/components/input';
import { ApiService } from '../../../services/api.service';
import { EjsService } from '../../../services/ejs.service';
import { SessionService } from '../../../services/session.service';

const ITEMS_GAP: number = 12;

@Component({
    selector: '.o-row'
})
export class RowComponent extends ComponentBase<HTMLElement> {

    @Input('apiUrl')
    private _apiUrl: string = null;

    private _data: any = null;

    @ChildRef('.o-row__header')
    private _headerElement: HTMLDivElement;
    @ChildRef('.o-row__items')
    private _itemsElement: HTMLDivElement;
    @ChildRef('.o-row__item-arrow--start')
    private _startArrowElement: HTMLDivElement;
    @ChildRef('.o-row__item-arrow--end')
    private _endArrowElement: HTMLDivElement;

    public constructor(node: HTMLElement, private _sessionService: SessionService, private _apiService: ApiService, private _ejsService: EjsService) {
        super(node);
    }    

    public onInit(mode?: 'load' | 'redirect'): void {
        if (this._apiUrl) {
            this.loadUserRow();
        }
    }

    @MouseEnterListener('.o-row__items-and-arrows')
    public onMouseEnter(): void {
        this.setOverflow();
    }

    @MouseLeaveListener('.o-row__items-and-arrows')
    public onMouseOut(): void {          
        this._endArrowElement.style.display = 'none';      
        this._startArrowElement.style.display = 'none';
    }

    @ClickListener('.o-row__item-arrow--start')
    public onStartArrowClick(): void {
        this.slide('start');
    }

    @ClickListener('.o-row__item-arrow--end')
    public onEndArrowClick(): void {
        this.slide('end');
    }    

    private setOverflow(): void {
        if (!document.body.classList.contains('touch')) {
            const items: HTMLLIElement[] = Array.from(this._itemsElement.querySelectorAll('.o-row__item'));
            const itemsWidth: number = items.sum(i => Dom.getComputedWidth(i)) + (items.length * ITEMS_GAP);
            if (itemsWidth > Dom.getComputedWidth(this._itemsElement) && (this._itemsElement.scrollLeft + this._itemsElement.offsetWidth + 1) < this._itemsElement.scrollWidth) {
                this._endArrowElement.style.display = 'flex';
            }
            else {
                this._endArrowElement.style.display = 'none';
            }
            if (this._itemsElement.scrollLeft) {
                this._startArrowElement.style.display = 'flex';
            }
            else {
                this._startArrowElement.style.display = 'none';
            }
        }
    }

    private slide(direction: 'start' | 'end'): void {        
        const itemsWidth: number = this._itemsElement.offsetWidth;
        const item: HTMLLIElement = this._itemsElement.querySelector('.o-row__item');
        const itemWidth: number = item.offsetWidth;
        const numberOfFullItems: number = Math.floor(itemsWidth / itemWidth);
        const distanceToScroll: number = numberOfFullItems * itemWidth;
        if (direction === 'end') {
            this._itemsElement.scrollLeft += distanceToScroll;
        } 
        else if (direction === 'start') {
            this._itemsElement.scrollLeft -= distanceToScroll;
        }
        setTimeout(() => this.setOverflow(), 500);
    }

    private async loadUserRow(): Promise<void> {
        if (this._sessionService.isSigned) {
            const response: any = await this._apiService.get(`/api/v1${this._apiUrl}`);
            if (response.items && response.items.length) {
                this.loadData(response);
            }
        }
    }

    public loadData(data: any): void {
        this._data = data;
        const { format, items } = data;
        this._node.classList.add(`o-row--${format}`);
        const headerHtml: string = this._ejsService.render('organisms/row/row-header', { data, TEXTS: USER_LOCALE });
        this._headerElement.innerHTML = headerHtml;
        const itemsHtml: string = (items || []).map((item: any, index: number) => this._ejsService.render('organisms/row/row-item', { data, item, TEXTS: USER_LOCALE, colindex: index + 1 })).join('');
        this._itemsElement.innerHTML = itemsHtml;
        this.fragment();
        this._node.classList.add('o-row--data-loaded');
                
        if (data.viewAllClick) {
            const viewAllElement: HTMLAnchorElement = this._node.querySelector('.o-row__viewAll a');
            if (viewAllElement) {
                viewAllElement.addEventListener('click', () => this._data.viewAllClick());
            }
        }
        if (data.itemClick) {
            const rowItems: HTMLAnchorElement[] = Array.from(this._node.querySelectorAll('.o-row__item'));
            rowItems.forEach((item, index) => {
                item.addEventListener('click', () => data.itemClick());
                item.setAttribute("aria-colindex", (index + 1).toString());
            });
        }

        this.dispatchCustomEvent('row-data-loaded', { ...data });
    }
}