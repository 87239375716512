import { Injectable } from "../../../lib/web/reflection/injectable";
import { ShareComponent } from "../components/molecules/share/share.component";
import { DataEventService } from "./data-event.service";

@Injectable({ type: 'singleton' })
export class ShareService {

    public constructor(private _dataEventService: DataEventService) {        
    }

    public init(): void {
        const shareElements: HTMLElement[] = Array.from(document.querySelectorAll('.u-share'));
        shareElements.forEach(e => {
            e.addEventListener('click', e => this.share(e.target as HTMLElement));
        });
    }

    private async share(e: HTMLElement): Promise<void> {
        let { shareTitle, shareDescription, shareUrl } = e.dataset;
        const { title } = document;
        const { href: url } = location;
        const description: string = ((document.querySelector('meta[name="description"]') || {}) as HTMLMetaElement).content;
        if (!shareTitle) {
            shareTitle = title;
        }
        if (!shareDescription) {
            shareDescription = description || title;
        }
        if (!shareUrl) {
            shareUrl = url;
        }
        // if (navigator.share) {
        //     navigator.share({
        //         title: shareTitle,
        //         text: shareDescription,
        //         url: shareUrl
        //     });
        // }
        // else {
            const share: ShareComponent = document.querySelector('.m-share').componentRef.ref as ShareComponent;
            const result = await share.open({ title: shareTitle, description: shareDescription, url: shareUrl });
            if (result) {
                this._dataEventService.share(e.dataset.shareAction, e.dataset.shareId, e.dataset.shareName, e.dataset.shareDisciplines, e.dataset.shareTypology, result, e.dataset.shareLocation);
            }
        // }
    }
}