import { Str } from "../../../lib/web/core/str";
import { Url } from "../../../lib/web/core/url";
import { Injectable } from "../../../lib/web/reflection/injectable";
import { ScrollService } from "../../../lib/web/services/scroll.service";
import { BootstrapService } from "./bootstrap.service";
import { PageService } from "./page.service";
import { TooltipService } from "./tooltip.service";

export interface RouteState {
    data?: any;
    popHandlerEvent?: string;
}
interface PageResponse {
    data: PageData,
    responseUrl: string;
}

interface PageData {
    metadata: PageMetadata,
    clientTemplates: ContentClientTemplate[],
    content: string;
}

interface ContentClientTemplate { 
    path: string;
    content: string;
}

interface PageMetadata {
    title?: string;
    description?: string;
    keywords?: string;
    canonical?: string;
    robots?: string;
    ogDescription?: string;
    ogImage?: string;
    localeId?: string;
    main?: string
}

@Injectable({ type: 'singleton' })
export class RouterService {

    private _bootstrapService: BootstrapService = null;
    private _isInitialized: boolean = false;
    private _isLoadingPage: boolean = false;

    public constructor(private _pageService: PageService, private _scrollService: ScrollService, private _tooltipService: TooltipService) {
    }

    public init(bootstrapService: BootstrapService): void {
        if (!this._isInitialized) {            
            this._bootstrapService = bootstrapService;

            history.replaceState(this.getState(), '');    

            window.addEventListener('popstate', e => {
                let isProcessed: boolean = false;
                if (e.state && e.state.popHandlerEvent) {
                    const detail: any = { 
                        isHandled: false,
                        state: e.state
                    };
                    window.dispatchEvent(new CustomEvent(e.state.popHandlerEvent, { detail }));
                    isProcessed = detail.isHandled;
                }
                if (!isProcessed) {
                    this.processPage(document.location.href, () => {
                        this._bootstrapService.start('redirect');
                        this.setState(e.state);                    
                    }, true);
                }
            });
            this._isInitialized = true;
        }
    }

    public processAnchors(): void {
        const anchors: HTMLAnchorElement[] = Array.from(document.querySelectorAll('a[href]:not([data-handled="true"]):not(.u-external-link)'));
        anchors.forEach(anchor => {
            anchor.dataset.handled = 'true';
            anchor.addEventListener('click', e => {
                if (Url.isOwnDomain(anchor.href)) {
                    e.preventDefault();
                    this.redirect(anchor.href);
                }
            });
        });
    }

    public reload(): void {
        this.processPage(`${location.pathname}${location.search}`, () => {            
            history.replaceState(null, '');
            this._bootstrapService.start('redirect');
            history.replaceState(this.getState(), '');
        });
    }

    public redirect(url: string): void {
        history.replaceState(this.getState(), '');
        this.processPage(url, () => { 
            this._bootstrapService.start('redirect');            
            history.replaceState(this.getState(), '');
        });   
    }

    private async processPage(url: string, callback: (responseUrl: string) => void, isBack: boolean = false): Promise<void> {
        this._isLoadingPage = true;
        try {
            this._tooltipService.hideTooltips();
            const pageLoaderElement: HTMLElement = document.querySelector('.page-loader');
            if (pageLoaderElement) {
                pageLoaderElement.outerHTML = `<div class="warm-up-container">
                    <div class="warm-up-loader"></div>
                </div>`;
            }
            this._scrollService.scrollToTop('auto');
            const response: PageResponse = await this._pageService.get(url, { });
            response.responseUrl = response.responseUrl || url;
            this.setPage(response, callback, isBack);
        }
        catch (e: any) {
            const response: any = Str.isJson(e) ? JSON.parse(e): e;
            if (response.data && response.data.metadata && response.data.content) {
                this.setPage(response, callback, isBack);
            }
            else {
                logger.error(e);
            }
        }
        finally {
            this._isLoadingPage = false;
            window.dispatchEvent(new CustomEvent('page-loaded'));
        }
    }

    private setPage(response: PageResponse, callback: (url: string) => void, isBack: boolean): void {
        this._bootstrapService.disposeNodes();
        
        const { responseUrl, data } = response;
        const { metadata, content, clientTemplates } = data;
        const { title, description, keywords, robots, ogDescription, ogImage, localeId, canonical } = metadata;

        if (!isBack) {
            history.pushState(null, '', responseUrl); 
        }
        if (this._bootstrapService.check()) {
            document.title = title;
            if (localeId) {
                document.documentElement.setAttribute('lang', localeId);
            }
            (window as any).clientTemplates = clientTemplates;
            document.querySelector('.main').outerHTML = content;
            (document.querySelector('meta[name="description"]') as HTMLMetaElement).content = description;
            (document.querySelector('meta[name="keywords"]') as HTMLMetaElement).content = keywords || '';
            (document.querySelector('meta[name="robots"]') as HTMLMetaElement).content = robots || '';
            (document.querySelector('meta[property="og:description"]')  as HTMLMetaElement).content = ogDescription;
            (document.querySelector('meta[property="og:image"]') as HTMLMetaElement).content = ogImage;
            (document.getElementById('canonical') as HTMLLinkElement).href = canonical;
            if (callback) {
                callback(response.responseUrl);
            }        
        }       
    }

    private getState(): RouteState {
        const state: RouteState = { 
            data: {}
        };
        this._bootstrapService.nodes.forEach(node => {
            const componentState: any = node.componentRef?.ref?.getState();
            if (componentState) {
                state.data = {
                    ...state.data,
                    ...componentState
                };
            }
        });
        return state;
    }

    private setState(state: RouteState): void {
        if (state && state.data) {
            this._bootstrapService.nodes.forEach(node => {
                node.componentRef.ref.setState(state.data);
            });
        }
    }

    public get isLoadingPage(): boolean {
        return this._isLoadingPage;
    }
}