import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.o-banner'
})
export class BannerComponent extends ComponentBase<HTMLElement> {

    @Input('parentSelector')
    private _parentSelector: string = null;
    @Input('audience', { isJSON: true })
    private _audience: string[] = null;
    @Input('genders', { isJSON: true })
    private _genders: string[] = null;
    @Input('languages', { isJSON: true })
    private _languages: string[] = null;
    @Input('postalCodes', { isJSON: true })
    private _postalCodes: string[] = null;
    private _userInfo: any = null;

    public constructor(node: HTMLElement, private _sessionService: SessionService) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        super.onInit(mode);
        this._userInfo = this._sessionService.isSigned ? this._sessionService.get()?.user :  null;
        this.checkVisibility();
    }

    private getVisisble() {
        let visible: boolean[] = [];
        const language: string = this._sessionService.getLanguage();
        if (this._audience?.length) {
            visible.push(this._audience.some(a => this.checkAudience(a)));
        }
        if (this._userInfo) {
            const { gender, postalCode } = this._userInfo;
            if (this._genders?.length && gender) {
                visible.push(this._genders.includes(gender));
            }
            if (this._languages?.length && language) {
                visible.push(this._languages.includes(language));
            }
            if (this._postalCodes?.length && postalCode) {
                visible.push(this._postalCodes.includes(postalCode));
            }
        } else {
            if (this._languages?.length && language) {
                visible.push(this._languages.includes(language));
            }
        }
        if (visible.length) {
            return visible.every(v => v);
        }
        return true;
    }

    private checkVisibility(): void {
        const visible: boolean = this.getVisisble();
        if (visible) {
            const parent: HTMLElement = this._node.closest(this._parentSelector);
            if (parent) {
                parent.classList.remove('u-hidden');
            }
        }
    }

    private checkAudience(audience: string): boolean {
        switch (audience) {
            case 'anonymous-user':
                return !this._sessionService.isSigned;
            case 'signed-in-user':
                return this._sessionService.isSigned;
            default:
                return false;
        }
    }
}