import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.p-welcome'
})
export class WelcomeComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement) {
        super(node);       
    }

    @ClickListener('.p-welcome__sign-in a') 
    public onSigIn(): void {
        setTimeout(() => {
            this.redirect('/u/sign-in');
        }, 25);
    }
}