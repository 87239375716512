import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.a-mini-player-menu'
})
export class MiniPlayerMenuComponent extends ComponentBase<HTMLInputElement> {

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    private show(): void {
        this.addClass('a-mini-player-menu--show');
    }

    public hide(): void {
        this.removeClass('a-mini-player-menu--show');
    }

    public toggle(): void {
        if (this._node.classList.contains('a-mini-player-menu--show')) {
            this.hide();       
        } 
        else {
            this.show();
        }
    }

    @ClickListener('.a-mini-player-menu__mini')
    public onMiniPlayer(): void {
        this.dispatchCustomEvent('mini-player');
    }

    @ClickListener('.a-mini-player-menu__pip')
    public onPip(): void {
        this.dispatchCustomEvent('pip');
    }
}