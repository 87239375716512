import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';

@Component({
    selector: '.a-icon'
})
export class IconComponent extends ComponentBase<HTMLInputElement> {
  
    @Input('key')
    private _key: string = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public set key(value: string) {
        if (!this.node.classList.contains(`a-icon--${value}`)) {
            this.node.classList.add(`a-icon--${value}`);
            this.node.querySelector('i').classList.add(`app-icons-${value}`);
            this.node.classList.remove(`a-icon--${this._key}`);
            this.node.querySelector('i').classList.remove(`app-icons-${this._key}`);
            this._key = value;        
        }
    }
}