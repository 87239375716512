import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { KeyUpListener } from '../../../../../lib/web/components/key-up-listener';
import { ModifierState } from '../../../../../lib/web/core/modifier-state';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: '.a-password-box'
})
export class PasswordBoxComponent extends ComponentBase<HTMLInputElement> {

    @ChildRef('input')
    private _inputElement: HTMLInputElement = null;
    @ChildRef()
    private _showIcon: IconComponent = null;
    @ChildRef('.a-password-box__checks-container')
    private _checksContainerElement: HTMLDivElement = null;
    @ChildRef('.a-password-box__checks')
    private _checksElement: HTMLUListElement = null;
    @ChildRef('.a-password-box__strength-level')
    private _strengthLevel: HTMLDivElement = null;
    @ChildRef('.a-password-box__strength-text')
    private _strengthText: HTMLDivElement = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(): void {
        (this._node as any).name = this._inputElement.name;
        if (this._checksContainerElement) {
            this._checksElement.childNodes.forEach(b => (b as HTMLElement).classList.add('a-password-box__check--error'))
        }
    }

    @ClickListener('.a-password-box__show-password')
    public onShowPassword(): void {
        this._inputElement.type = this._inputElement.type == 'password' ? 'text' : 'password';
        this._showIcon.key = this._inputElement.type == 'password' ? 'show' : 'hide';
        this.focus();
    }

    @EventListener('focus', 'input')
    public onFocus(): void {
        if (this._checksContainerElement) {
            this._checksContainerElement.style.display = 'block';
        }
        this.dispatchCustomEvent('focus');
    }

    @EventListener('blur', 'input')
    public onBlur(): void {
        setTimeout(() => {
            if (this._checksContainerElement && document.activeElement.tagName !== "BUTTON") {
                this._checksContainerElement.style.display = 'none';
            }
        }, 0);
        this.clearErrors('.u-error--password--capsLock');
        this.clearErrors('.u-error--password--numLock');
        this.dispatchCustomEvent('blur');
    }

    @EventListener('input', 'input')
    public onInput(): void {
        this.addOrRemoveClass(this.value && !this._inputElement.disabled, 'a-password-box--not-empty');
        if (this._checksContainerElement) {
            this.validateChecks(this.value);
        }
        this.dispatchCustomEvent('input');
    }

    @KeyUpListener('input')
    public onPasswordKeyUp(event: KeyboardEvent): void {
        if (event?.code) {
            if (event?.getModifierState(ModifierState.CAPS_LOCK)) {
                this.setErrors('.u-error--password--capsLock', USER_LOCALE.capsLockActivated);
            }
            else {
                this.clearErrors('.u-error--password--capsLock');
            }
            if (event?.getModifierState(ModifierState.NUM_LOCK)) {
                this.setErrors('.u-error--password--numLock', USER_LOCALE.numLockActivated);
            }
            else {
                this.clearErrors('.u-error--password--numLock');
            }
        }
    }

    private validateChecks(input: string): void {
        const lengthCheck: boolean = input.length >= 8 && input.length < 16;
        const digitCheck: boolean = RegExp('(?=.*\\d)', 'g').test(input);
        const uppercaseAlphaCheck: boolean = RegExp('(?=.*[A-Z])').test(input);
        const lowercaseAlphaCheck: boolean = RegExp('(?=.*[a-z])').test(input);
        const allowedSpecialCharsCheck: boolean = RegExp('(?=.*[!~<>?=_+,;:@#.$%()\\[\\]\/|\^&\*])').test(input);
        this.check(0, lengthCheck);
        this.check(1, digitCheck);
        this.check(2, uppercaseAlphaCheck);
        this.check(3, lowercaseAlphaCheck);
        this.check(4, allowedSpecialCharsCheck);

        const isFair: boolean = lengthCheck && digitCheck && uppercaseAlphaCheck && lowercaseAlphaCheck && allowedSpecialCharsCheck;
        this.handleStrenghtBar(input, isFair)
    }

    private handleStrenghtBar(input: string, isFair: boolean): void {
        this._strengthLevel.className = `a-password-box__strength-level`;
        this._strengthText.className = `a-password-box__strength-text`;
        if (input.length > 0) {
            if (isFair) {
                if (input.length >= 10) {
                    this._strengthLevel.classList.add(`a-password-box__strength-level__75`);
                    this._strengthText.className = `a-password-box__strength-text__75`;
                    this._strengthText.innerText = USER_LOCALE.strengthBarGoodText;
                    if (input.length > 14) {
                        this._strengthLevel.classList.add(`a-password-box__strength-level__100`);
                        this._strengthText.innerText = USER_LOCALE.strengthBarExcellentText;
                        this._strengthText.className = `a-password-box__strength-text__100`;
                    }
                }
                else {
                    this._strengthLevel.classList.add(`a-password-box__strength-level__50`);
                    this._strengthText.innerText = USER_LOCALE.strengthBarFairText;
                    this._strengthText.className = `a-password-box__strength-text__50`;
                }
            }
            else {
                this._strengthLevel.classList.add(`a-password-box__strength-level__25`);
                this._strengthText.innerText = USER_LOCALE.strengthBarUnFairText;
                this._strengthText.className = `a-password-box__strength-text__25`;
            }
        } else {
            this._strengthLevel.className = 'a-password-box__strength-level';
            this._strengthText.innerText = '';
            this._strengthText.className = 'a-password-box__strength-text';
        }
    }

    private check(index: number, success?: boolean): void {
        if (success) {
            this._checksElement.children[index].classList.replace('a-password-box__check--error', 'a-password-box__check--success');
        }
        else {
            this._checksElement.children[index].classList.replace('a-password-box__check--success', 'a-password-box__check--error');
        }
    }

    public focus(): void {
        this._inputElement.focus();
    }

    public get value(): string {
        return this._inputElement.value;
    }

    public set value(value: string) {
        this._inputElement.value = value;
        this.onInput();
    }
}