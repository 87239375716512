import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { PopupComponent } from '../../../../../lib/web/components/popup.component';

@Component({
    selector: '.a-item-selector-dropdown'
})
export class ItemSelectorDropdownComponent extends PopupComponent<HTMLInputElement> {

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public async open(data: { element?: HTMLElement, mode: 'centered', selected?: any }, e?: MouseEvent): Promise<any> {       
        const { element, selected, mode }  = data;
        this.setSelected(selected);
        const promise: Promise<any> = super.open(data, e);
      
        this._node.style.top = null;
        this._node.style.bottom = null;

        const { width: nodeWith } = this._node.getBoundingClientRect();
        const { offsetHeight, offsetWidth } = document.documentElement;
        const { right: parentRight, top: parentTop, bottom: parentBottom, height: parentHeight, width: parentWidth } = element.getBoundingClientRect();
        if (mode == 'centered') {
            this._node.style.right = `${offsetWidth - parentRight - ((nodeWith - parentWidth) / 2)}px`;
        }
        else {
            this._node.style.right = `${offsetWidth - parentRight}px`;
        }
        if ((offsetHeight - parentBottom) > parentTop) {
            this._node.style.top = `${parentBottom + 3}px`;
        }
        else {
            this._node.style.bottom = `${offsetHeight - parentTop + 10}px`;
        }
        return promise;
    }

    @ClickListener('.a-item-selector-dropdown__item button')
    public onItemClicked(e: MouseEvent): void {
        this.close((e.currentTarget as HTMLButtonElement).dataset.id);
    }

    private setSelected(selected: any): void {
        const buttons: HTMLButtonElement[] = Array.from(this._node.querySelectorAll('.a-item-selector-dropdown__item button'));
        buttons.forEach(b => b.classList.remove('u-button--selected'));
        const button: HTMLButtonElement = buttons.find(b => b.dataset.id == selected);        
        if (button) {
            button.classList.add('u-button--selected');
        }
    }
}