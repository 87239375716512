import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { ApiService } from '../../../services/api.service';
import { ToggleSelectorComponent } from '../../atoms/toggle-selector/toggle-selector.component';

@Component({
    selector: '.p-first-access'
})
export class FirstAccessComponent extends ComponentBase<HTMLElement> {

    @ChildRef()
    private _disciplineSelector: ToggleSelectorComponent = null;

    public constructor(node: HTMLElement, private _apiService: ApiService) {
        super(node);   
    }   

    @ClickListener('.p-first-access__start')
    public async onStart(): Promise<void> {
        const selected: number[] = this._disciplineSelector.selected;
        if (selected && selected.length) {
            await this._apiService.post('/api/v1/user/disciplines', { 
                body: {
                disciplines: selected
                }
            });                
        }
        this.redirect('/');
    }
}