import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { ApiService } from '../../../services/api.service';
import { GridComponent } from '../../organisms/grid/grid.component';

@Component({
    selector: '.p-list-view'
})
export class ListViewComponent extends ComponentBase<HTMLElement> {

    @ChildRef()
    private _grid: GridComponent = null;
    
    public constructor(node: HTMLElement, private _apiService: ApiService) {
        super(node);       
    }

    public onInit(): void {
        super.onInit();
        this._grid.addCustomEventListener('load-page', (data: { page: number }) => this.loadPage(data));
    }

    private async loadPage(data: { page: number }): Promise<void> {
        const { apiUrl } = this._node.dataset;
        const { page } = data;
        if (apiUrl) {
            const response: { items: any[], pageInfo: { currentPage: number, totalPages: number } } = await this._apiService.get(apiUrl, { 
                query: {                
                    page
                }
            });            
            this._grid.setPageResponse(response.items, response.pageInfo);
        }
    }
}