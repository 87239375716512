import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { KeyDownListener } from '../../../../../lib/web/components/key-down-listener';
import { Key } from '../../../../../lib/web/core/key';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ScrollService } from '../../../../../lib/web/services/scroll.service';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';
import { ValidationService } from '../../../services/validation.service';
import { InputPinComponent } from '../../atoms/input-pin/input-pin.component';

@Component({
    selector: '.p-renew-parental-pin'
})
export class RenewParentalPinComponent extends ComponentBase<HTMLElement> {

    @Input('token')
    private _token: string = null;
    
    @ChildRef('#parentalPin')
    private _txtParentalPin: InputPinComponent = null;
    @ChildRef('#repeatParentalPin')
    private _txtRepeatParentalPin: InputPinComponent = null;
    
    public constructor(node: HTMLElement, 
        private _apiService: ApiService,
        private _validationService: ValidationService,
        private _scrollService: ScrollService,
        private _sessionService: SessionService) {
        super(node);       
    }   

    public onInit(): void {
        this.registerFormFieldInput('.p-renew-parental-pin__form', field => {
            if (this._txtParentalPin.value && this._txtRepeatParentalPin.value) {
                this.validate();
            }
            else {
                this.validateField(field);
            }
        });
        this.addClass(this._sessionService.isSigned ? 'p-renew-parental-pin--signed': 'p-renew-parental-pin--unsigned');

    }

    @KeyDownListener(Key.Enter, '#parentalPin')
    public onParentalPinEnter(): void {
        this.onSend();    
    }

    @KeyDownListener(Key.Enter, '#repeatParentalPin')
    public onRepeatParentalPinEnter(): void {
        this.onSend();      
    }
    
    @ClickListener('.p-renew-parental-pin__send button') 
    public async onSend(): Promise<void> {
        if (this.validate()) {
            const { parentalPin } = this.getFormData('.p-renew-parental-pin__form');
            try {
                await this._apiService.post('/api/v1/user/renew-parental-pin', { 
                    body: {
                        parentalPin,
                        token: this._token
                    }
                });
                this.addClass('p-renew-parental-pin--changed');
            }
            catch (e: any) {
                if (e.statusCode == 400) {
                    this._validationService.showErrors('.p-renew-parental-pin__form', JSON.parse(e.data?.message));
                    this.setErrors('.p-renew-parental-pin__errors', `${e.statusCode}: ${e.data?.message}`);
                    this._scrollService.scrollToTop();    
                }
                else {
                    this.redirect('/u/retry-forgot-parental-pin')
                }
            }
        }
    }

    public validate(): boolean {
        const data: any = this.getFormData('.p-renew-parental-pin__form')
        const errors: DictionaryObject<string[]> = this._validationService.validateParentalPin(data, data, 4);
        this._validationService.showErrors('.p-renew-parental-pin__form', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        const errors: DictionaryObject<string[]> = this._validationService.validateParentalPin(field, this.getFormData('.p-renew-parental-pin__form'), 4);
        this._validationService.showErrors('.p-renew-parental-pin__form', errors);
    }
}