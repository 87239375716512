import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { PopupComponent } from '../../../../../lib/web/components/popup.component';
import { DataEventService } from '../../../services/data-event.service';
import { SessionService } from '../../../services/session.service';
import { MobileDropdownMenuComponent } from '../mobile-dropdown-menu/mobile-dropdown-menu.component';

@Component({
    selector: '.m-mobile-menu'
})
export class MobileMenuComponent extends PopupComponent<HTMLInputElement> {

    @ChildrenRef(HTMLAnchorElement, '.m-mobile-menu__item')
    public _items: (HTMLAnchorElement | HTMLButtonElement)[] = null;
    @ChildrenRef(MobileDropdownMenuComponent)
    public _dropdowns: MobileDropdownMenuComponent[] = null;
    
    public constructor(node: HTMLInputElement, private _sessionService: SessionService, private _dataEventService: DataEventService) {
        super(node);
    }

    public onInit(): void {
        super.onInit();
        this.addClass(this._sessionService.isSigned ? 'm-mobile-menu--signed': 'm-mobile-menu--unsigned');
        this.setSelected();
    }

    @ClickListener('.m-mobile-menu__close')
    public onClose(): void {
        this.close();
    }

    @ClickListener('.m-mobile-menu__previous')
    public onPreviuous(): void {
        this._node.classList.remove('m-mobile-menu--dropdown');
        this._dropdowns.forEach(d => d.removeClass('m-mobile-dropdown-menu--show'));
    }

    @ClickListener('.m-mobile-menu__title')
    public onHome(): void {
        this.close(true);
    }

    @ClickListener('.m-mobile-menu__access a')
    public onAccess(): void {
        this.close(true);
    }

    @ClickListener('.m-mobile-menu__item')
    public onItemClicked(e: MouseEvent): void {
        const item: HTMLAnchorElement = e.currentTarget as HTMLAnchorElement;
        if (item.classList.contains('m-mobile-menu__item--dropdown')) {
            const index: number = parseInt(item.dataset.index);
            this._dropdowns.forEach(d => {
                if (parseInt(d.node.dataset.index) == index) {
                    d.addClass('m-mobile-dropdown-menu--show');
                }
                else {
                    d.removeClass('m-mobile-dropdown-menu--show');
                }
            });
            this._node.classList.add('m-mobile-menu--dropdown');
        }
        else {
            this.close(true);
        }
    }

    @ClickListener('.m-mobile-dropdown-menu__item')
    public onDropdownItemClicked(e: MouseEvent): void {
        const item = (e.currentTarget as HTMLLIElement).children[0] as HTMLAnchorElement;
        this.sendClickMenuEvent(item);
        this.close(true);
    }

    public async open(data?: any, e?: MouseEvent): Promise<void> {               
        const promise: Promise<void> = super.open(data, e);
        this._node.classList.remove('m-mobile-menu--closed');
        this._node.classList.add('m-mobile-menu--opened');
        return promise;
    }

    public close(instant: boolean = false): void {
        if (this.isOpened) {
            if (instant) {
                super.close();
                this._node.classList.add('m-mobile-menu--closed');
                this._node.classList.remove('m-mobile-menu--opened', 'm-mobile-menu--dropdown');
            }
            else {
                this._node.classList.remove('m-mobile-menu--opened');
                setTimeout(() => {
                    super.close();
                    this._node.classList.remove('m-mobile-menu--dropdown');
                }, 500);        
            }
        }
    }

    private setSelected(): void {        
        const { pathname } = location;
        const selected: HTMLAnchorElement | HTMLButtonElement = this._items.find(i => {
            const path: string = (i as any)?.href?.replace(location.origin,'');
            return ['/', '/home'].includes(pathname) ? ['/', '/home'].includes(path): path == pathname;
        });
        if (selected) {
            selected.classList.add('m-mobile-menu__item--selected');
        }
    }

    private sendClickMenuEvent(item: HTMLAnchorElement) {
        const { href, innerText } = item;
        this._dataEventService.clickMenu(href, innerText, innerText, 'mobile-menu', document.title, location.href);
    }
}