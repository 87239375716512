import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { KeyDownListener } from '../../../../../lib/web/components/key-down-listener';
import { Key } from '../../../../../lib/web/core/key';
import { ApiService } from '../../../services/api.service';
import { ScrollService } from '../../../../../lib/web/services/scroll.service';

@Component({
    selector: '.p-env-code'
})
export class EnvCodeComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement, private _apiService: ApiService, private _scrollService: ScrollService) {
        super(node);        
    }    

    public onInit(): void {
        this.focus('#txtCode');
    }

    @KeyDownListener(Key.Enter, '#txtCode')
    public onCodeEnter(): void {
        this.validate();
    }

    @ClickListener('.p-env-code__send')
    public onSend(): void {
        this.validate();
    }  

    private async validate(): Promise<void> {
        const { code } = this.getFormData('.p-env-code__form');
        try {
            if (code) {
                await this._apiService.post('/api/v1/env/code', { 
                    body: {
                        code,
                    }
                });                
                this.redirect('/');
            }
        }
        catch (e: any) {
            if (e == 401 || e.statusCode == 401) {
                this.setErrors('.p-env-code__errors', USER_LOCALE.envCodeFailed);
            }
            else {
                this.showUnexpectedError(e);
            }
            this._scrollService.scrollToTop();
        }
    }
}