import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.m-player-segments'
})
export class PlayerSegmentsComponent extends ComponentBase<HTMLDivElement> {


    @ChildRef('ul')
    private _list: HTMLUListElement = null;

    public constructor(node: HTMLDivElement) {
        super(node);
    }
    
    public show(): void {
        this._node.scrollTop = 0;
    }

    public set(segments: any[]): void {
        this.clear();
        (segments || []).forEach(s => this.addSegment(s));
    }

    public clear(): void {
        this._list.innerHTML = '';
    }

    private addSegment(segment: any): void {
        const { title } = segment;
        const itemElement: HTMLLIElement = document.createElement('li');
        const btnElement: HTMLButtonElement = document.createElement('button');
        btnElement.role = 'menuitem';
        btnElement.addEventListener('click', e => this.dispatchCustomEvent('selected', (e.target as any).segment));
        btnElement.textContent = title;
        (btnElement as any).segment = segment;
        itemElement.appendChild(btnElement);
        this._list.appendChild(itemElement);
    }
}