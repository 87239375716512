import { Injectable } from "../../../lib/web/reflection/injectable";
import { HttpClientResponse, HttpService } from "../../../lib/web/services/http.service";
import { SessionService } from "./session.service";

@Injectable({ type: 'singleton'})
export class PageService {

    public constructor(private _httpService: HttpService, private _sessionService: SessionService) {
    }

    public async get(url: string, data?: { headers?: any, query?: any }): Promise<any> {
        try {
            const { headers } = data || {};
            const response: HttpClientResponse = await this._httpService.get(url, {
                ...data,
                headers: this.getPageHeaders(headers),
            });
            return this.getPageResponse(response, url);
        }
        catch (e: any) {
            throw this.getPageResponse(e, url, true);
        }
    }

    private getPageResponse(response: HttpClientResponse, url: string, isError?: boolean): any {
        const { statusCode, data, responseUrl } = response;
        try {  
            if (statusCode == 205) {
                location.assign(url);
            }
            else if (isError) {
                if (statusCode == 499) {
                    location.assign('/waiting-room');
                }
            }            
            return {
                data: JSON.parse(data),
                responseUrl
            };
        }
        catch (e: any) {
            return response;
        }
    }

    private getPageHeaders(headers: any): any {
        const apiHeaders: any = {                
            ...(headers || {}),
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Page-Content': '1'
        };
        const version: HTMLMetaElement = document.querySelector('meta[property="cxf+:version"]');
        if (version) {
            apiHeaders['X-Version'] = version.content;
        }
        return apiHeaders;
    }  
}