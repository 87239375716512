import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { ItemSelectorDropdownComponent } from '../item-selector-dropdown/item-selector-dropdown.component';
import { ItemSelectorModalComponent } from '../item-selector-modal/item-selector-modal.component';

@Component({
    selector: '.a-item-selector'
})
export class ItemSelectorComponent extends ComponentBase<HTMLElement> {

    @Input('items', { isJSON: true })
    private _items: any[] = null;
    @Input('selected')
    private _selected: string = null;
    @Input('id')
    private _id: string = null;
    @Input('display1')
    private _display1: string = null;
    @Input('display2')
    private _display2: string = null;

    @ChildRef()
    private _dropdown: ItemSelectorDropdownComponent = null;
    @ChildRef()
    private _modal: ItemSelectorModalComponent = null;

    public constructor(node: HTMLElement) {
        super(node);
    }

    @ClickListener('.a-item-selector__selected')
    public async onProfile(e: MouseEvent): Promise<void> {
        const arrowElement: HTMLElement = this._node.querySelector('.u-arrow');
        arrowElement.classList.remove('u-arrow--down');
        arrowElement.classList.add('u-arrow--up');
        const selectedId: any = await this.openSelector(e);
        arrowElement.classList.remove('u-arrow--up');
        arrowElement.classList.add('u-arrow--down');

        if (selectedId) {
            if (this.selectionMode == 'default') {
                this.setSelected(selectedId);
            }
            else {
                this.dispatchCustomEvent('selection-changed', { id: selectedId });
            }
        }
    }   
    
    private async openSelector(e: MouseEvent): Promise<any> {
        return await this._dropdown.toggle({ element: this._node, selected: this._selected }, e);
    }

    public setSelected(id: string): void {
        if (this._selected != id) {
            const item: any = this._items.find(i => i[this._id] == id);
            this._selected = item[this._id];
            this.setInnerHTML(item[this._display1], '.a-item-selector__display1');
            if (this._display2) {
                this.setInnerHTML(item[this._display2], '.a-item-selector__display2');
            }
            if (this.selectionMode == 'default') {
                this.dispatchCustomEvent('selection-changed', { id: this._selected });
            }
        }
    }

    public get selectionMode(): 'default' | 'external' {
        return this._node.dataset.selectionMode as 'default' | 'external';
    }
}