import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { MouseEnterListener } from '../../../../../lib/web/components/mouse-enter-listener';
import { MouseLeaveListener } from '../../../../../lib/web/components/mouse-leave-listener';

@Component({
    selector: '.o-slider'
})
export class SliderComponent extends ComponentBase<HTMLElement> {

    private _intervalTimer: NodeJS.Timer = null;
    private _touchX: number = null;
    private _touchY: number = null;
    private _touchDirection: 'next' | 'previous' = null;
    private _isMouseEnter: boolean = false;
    private _isTouchMoving: boolean = false;

    @ChildrenRef(HTMLDivElement, '.o-slider__slide')
    private _slideElements: HTMLDivElement[] = null;
    @ChildrenRef(HTMLDivElement, '.o-slider__bullet')
    private _bulletElements: HTMLButtonElement[] = null;
    @ChildRef('.o-slider__item-arrow--start')
    private _startArrowElement: HTMLDivElement;
    @ChildRef('.o-slider__item-arrow--end')
    private _endArrowElement: HTMLDivElement;

    public constructor(node: HTMLElement) {
        super(node);
    }

    public onInit(): void {
        this.slide(0);
        this._intervalTimer = setInterval(() => this.slide('next'), 5000);
        this._bulletElements.forEach(b => b.addEventListener('click', () => this.slide(parseInt(b.dataset.index), true)));

        this._slideElements.forEach(b => b.addEventListener('touchstart', e => this.onTouchStart(e)));
        this._slideElements.forEach(b => b.addEventListener('touchmove', e => this.onTouchMove(e)));
        this._slideElements.forEach(b => b.addEventListener('touchend', e => this.onTouchEnd(e)));
    }
    
    @MouseEnterListener()
    public onMouseEnter(): void {
        this._isMouseEnter = true;
        this.setArrows();
    }

    @MouseLeaveListener()
    public onMouseOut(): void {
        this._isMouseEnter = false;          
        this._endArrowElement.style.display = 'none';      
        this._startArrowElement.style.display = 'none';
    }

    @ClickListener('.o-slider__item-arrow--start')
    public onStartArrowClick(): void {
        this.slide('previous', true);
    }

    @ClickListener('.o-slider__item-arrow--end')
    public onEndArrowClick(): void {
        this.slide('next', true);
    }
    
    private slide(direction?: 'next' | 'previous' | number, force?: boolean): void {
        const index: number = typeof direction === 'number' ? direction: direction == 'next' ? this.nextIndex: this.previousIndex;
        if (force && this._intervalTimer) {
            clearInterval(this._intervalTimer);
            this._intervalTimer = null;
        }
        this._slideElements.forEach(s => s.dataset.active = 'false');
        const slide: HTMLDivElement = this._slideElements[index];
        slide.dataset.active = 'true';  
        this._bulletElements.forEach(s => s.dataset.active = 'false');
        const bullet: HTMLButtonElement = this._bulletElements[index];
        bullet.dataset.active = 'true';
        if (this._isMouseEnter)  {
            this.setArrows();
        }
    }

    private onTouchStart(event: TouchEvent): void {
        const touch: Touch = event.touches ? event.touches[0] : null;
        if (touch) {
            this._touchX = touch.clientX;
            this._touchY = touch.clientY;
        }
    }

    private onTouchMove(event: TouchEvent): void {
        const touch: Touch = event.touches ? event.touches[0] : null;
        if (touch) {
            this._isTouchMoving = true;
            const deltaX: number = -1 * (touch.clientX - this._touchX);
            const deltaY: number = -1 * (touch.clientY - this._touchY);
            if (deltaX > 0) {
                this._touchDirection = 'next';
            } 
            else {
                this._touchDirection = 'previous';
            }
        }
    }

    private onTouchEnd(event: TouchEvent): void {
        if (this._isTouchMoving) {
            this._isTouchMoving = false;
            if (this._touchDirection == 'next') {
                this.slide('next', true)
            } 
            else if (this._touchDirection == 'previous') {
                this.slide('previous');
            }
        }
    }

    private setArrows(): void {
        if (!document.body.classList.contains('touch')) {
            if (this.currentIndex != this.nextIndex) {
                this._startArrowElement.style.display = 'flex';
                this._endArrowElement.style.display =  'flex';
            }
        }
    }

    public get currentIndex(): number {
        return parseInt(this._slideElements.find(s => s.dataset.active === 'true').dataset.index);
    }

    public get nextIndex(): number {
        const currentIndex: number = this.currentIndex;
        if (currentIndex < this._slideElements.length - 1) {
            return currentIndex + 1;
        }
        else {
            return 0;
        }
    }

    public get previousIndex(): number {
        const currentIndex: number = this.currentIndex;
        if (currentIndex > 0) {
            return currentIndex - 1;
        }
        else {
            return this._slideElements.length - 1;
        }
    }
}