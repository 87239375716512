import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Str } from '../../../../../lib/web/core/str';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ScrollService } from '../../../../../lib/web/services/scroll.service';
import { SessionStorageService } from '../../../../../lib/web/services/session-storage.service';
import { ApiService } from '../../../services/api.service';
import { DataEventService } from '../../../services/data-event.service';
import { SessionService } from '../../../services/session.service';
import { ValidationService } from '../../../services/validation.service';
import { PasswordBoxComponent } from '../../atoms/password-box/password-box.component';
import { TextBoxComponent } from '../../atoms/text-box/text-box.component';

@Component({
    selector: '.o-register'
})
export class RegisterComponent extends ComponentBase<HTMLElement> {

    @ChildRef('#txtEmail')
    private _txtEmail: TextBoxComponent = null;
    @ChildRef('#txtRepeatEmail')
    private _txtRepeatEmail: TextBoxComponent = null;
    @ChildRef('#txtPassword')
    private _txtPassword: PasswordBoxComponent = null;
    @ChildRef('#txtRepeatPassword')
    private _txtRepeatPassword: PasswordBoxComponent = null;
    
    public constructor(node: HTMLElement, 
        private _apiService: ApiService, 
        private _validationService: ValidationService,
        private _scrollService: ScrollService,
        private _sessionService: SessionService,
        private _sessionStorageService: SessionStorageService,
        private _dataEventService: DataEventService) {
        super(node);
    }

    public onInit(): void {
        this.addCustomWindowEventListener('country-selected', country => {
            this.setFormData({ postalCode: null });
            this.addOrRemoveClass(![CONFIG.SPAIN_COUNTRY_ID, CONFIG.PORTUGAL_COUNTRY_ID].includes(country.id), 'u-hidden', '.o-register__postalCode');
        });
        this.registerFormFieldInput('.o-register__content', field => { 
            this.validateField(field);
            if (field.email != undefined && this._txtEmail.value && this._txtRepeatEmail.value) {
                this.validateField({ repeatEmail: this._txtRepeatEmail.value });
            }
            if (field.repeatEmail != undefined && this._txtEmail.value && this._txtRepeatEmail.value) {
                this.validateField({ email: this._txtEmail.value });
            }
            if (field.password != undefined && this._txtPassword.value && this._txtRepeatPassword.value) {
                this.validateField({ repeatPassword: this._txtPassword.value });
            }
            if (field.repeatPassword != undefined && this._txtPassword.value && this._txtRepeatPassword.value) {
                this.validateField({ password: this._txtPassword.value });
            }
        });
        this.focus('#txtName');
    }

    @ClickListener('.o-register__submit')
    public onSubmit(): void {
        if (this.validate()) {
            this.submit();
        }
        else {
            this._scrollService.scrollTo('.o-register');
        }
    }    

    private async submit(): Promise<void> {
        try {
            this.disable('.o-register__submit');
            const user: any = this.getFormData('.o-register__content');
            const registerFrom: string = this._sessionStorageService.get('registerFrom');
            const { redirectUrl, userId } = await this._apiService.post('/api/v1/user', { 
                body: { 
                    ...user,
                    tags: registerFrom ? [{ name: 'registerFrom', value: registerFrom }]: undefined
                }
            });
            this._sessionStorageService.remove('registerFrom');
            this._sessionService.showMessageAfterRegisterOrLogin();
            this._sessionService.setLanguage(user.language);
            this._dataEventService.completeRegistration(userId, 'email');
            this.redirect(redirectUrl);
        }
        catch (e: any) {
            if (e.statusCode == 409 && e.data?.message == 'E-EMAIL-CONFLICT') {
                this.setErrors('.o-register__errors', USER_LOCALE.emailAlreadyExists);
            }
            else if (e.statusCode == 400) {
                if (Str.isJson(e.data?.message)) {
                    this._validationService.showErrors('.o-register__content', JSON.parse(e.data?.message));
                }
                else {
                    this.showInfo(USER_LOCALE.error, e.data?.message);
                }
            }
            else {
                this.showUnexpectedError(e);
            }
            this.cleanPasswords();
            this._scrollService.scrollToTop();
        }
        finally {
            this.enable('.o-register__submit');
        }    
    }    
    
    public validate(): boolean {
        this.setErrors('.o-register__errors');
        const data: any = this.getFormData('.o-register__content')
        const errors: DictionaryObject<string[]> = this._validationService.validateRegister(data, data);
        this._validationService.showErrors('.o-register__content', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        if (field && field.postalCode) {
            const postalCode = this._validationService.formatPostalCode(field.postalCode, this.getFormData('.o-register__content'));
            if (field.postalCode != postalCode) {
                this.setFormData({ postalCode });
            }
        }
        const errors: DictionaryObject<string[]> = this._validationService.validateRegister(field, this.getFormData('.o-register__content'));
        this._validationService.showErrors('.o-register__content', errors);           
    }

    private cleanPasswords(): void {
        this.setFormData({ password: null, repeatPassword: null});
    }

    public getState(): any {
        const data: any = this.getFormData('.o-register__content', 'password', 'repeatPassword');
        return {
            'register-data': data
        };
    }

    public setState(state: any): void {
        const registerData: any = state['register-data'];
        if (registerData) {
            this.setFormData(registerData);
        }
    }
}