import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';
import { DataEventService } from '../../../services/data-event.service';
import { ToggleSelectorComponent } from '../../atoms/toggle-selector/toggle-selector.component';

interface SearchFilterGroup {
    title: string;
    name: string;
    type: string;
    value: number | number[];
}

@Component({
    selector: '.m-search-filter'
})
export class SearchFilterComponent extends ModalComponent<HTMLInputElement> {

    private _activeFilter: SearchFilterGroup[] = null;

    @ChildrenRef(HTMLDivElement, '.m-search-filter__group')
    private _groups: HTMLDivElement[] = null;
    @ChildrenRef(ToggleSelectorComponent)
    private _toggleSelectors: ToggleSelectorComponent[] = null;

    public constructor(node: HTMLInputElement, private _dataEventService: DataEventService) {
        super(node);
    }

    @ClickListener('.m-search-filter__close')
    public onClose(): void {
        this.close();
    }

    @ClickListener('.m-search-filter__search')
    public onSearch(): void {
        this.close(true);
    }

    @ClickListener('.m-search-filter__group-header')
    public onToogleGroupContent(e: MouseEvent): void {
        const headerElement: HTMLDivElement = e.currentTarget as HTMLDivElement;
        this.toggleGroup(headerElement.closest('.m-search-filter__group'));
    }

    private toggleGroup(groupElement: HTMLDivElement): void {
        const headerElement: HTMLDivElement = groupElement.querySelector('.m-search-filter__group-header');
        const contentElement: HTMLDivElement = groupElement.querySelector('.m-search-filter__group-content');
        contentElement.classList.toggle('m-search-filter__group-content--opened');
        const arrowElement: HTMLDivElement = headerElement.querySelector('.u-arrow');
        arrowElement.classList.toggle('u-arrow--down');
        arrowElement.classList.toggle('u-arrow--up');
    }

    private closeGroups(): void {
        const opened: HTMLDivElement[] = this._groups.filter(g => g.querySelector('.m-search-filter__group-content').classList.contains('m-search-filter__group-content--opened'));
        opened.forEach(g => this.toggleGroup(g));
    }

    public async open(): Promise<boolean> { 
        this._activeFilter = this.getFilter();  
        const promise: Promise<boolean> = super.open();        
        this._node.classList.add('m-search-filter--opened');
        return promise;
    }

    public close(search: boolean = false): void {
        if (search || !this.hasChanges()) {
            this._node.classList.remove('m-search-filter--opened');
            super.close(search);
            this.closeGroups();
        }
        else if (this.hasChanges()) {
            this.promptPendingChanges();
        }
        else {
        }
    }

    public sendDataFilterEvent(filter: SearchFilterGroup): void {
        this._dataEventService.selectFilter(filter.name, filter.type, filter.value);
    }

    private async promptPendingChanges(): Promise<void> {
        if ((await this.showQuestion(USER_LOCALE.pendingFilters, USER_LOCALE.pendingFiltersQuestion, 'ok', { title: USER_LOCALE.exit, result: 'cancel' },{ title: USER_LOCALE.applyFilters, result: 'ok' })) == 'ok') {
            this._activeFilter = this.getFilter();
            this.close(true);
        }
        else {
            this.setFilter(this._activeFilter);
            this.close(false);
        }        
    }

    private hasChanges(): boolean {
        let hasChanges: boolean = false;
        const currentFilter: SearchFilterGroup[] = this.getFilter();        
        if (!this.includesFilter(currentFilter, this._activeFilter) || !this.includesFilter(this._activeFilter, currentFilter)) {
            hasChanges = true;
        }
        return hasChanges;
    }

    private includesFilter(filter1: SearchFilterGroup[], filter2: SearchFilterGroup[]): boolean {
        let includes: boolean = true;
        filter1.forEach(f1 => {
            const f2: SearchFilterGroup = filter2.find(f => f.name == f1.name);
            const { value: value1 } = f1;
            const { value: value2 } = f2;
            if (Array.isArray(value1)) {
                if (!value1.every(v1 => (value2 as number[]).includes(v1))) {
                    includes = false;
                }
            }
            else {
                if (value1 != value2) {
                    includes = false;
                }
            }
        });
        return includes;
    }

    private async checkChanges(): Promise<void> {
        this._node.classList.add('m-search-filter--closed');
        setTimeout(() => {
            super.close(false);
        }, 500);   
    }

    public setFilter(filter: SearchFilterGroup[]): void {
        filter.forEach((f, index) => {            
            const { name, value, type } = f;
            this.deselectGroup(name);
            const group: HTMLDivElement = this._groups.find(g => g.dataset.name == name);
            switch (type) {
                case 'toggle':
                    this._toggleSelectors.find(t => t.node.id == `toggle-${name}`).selected = value as number[];
                    break;
                case 'single':
                    if (value) {
                        (group.querySelector(`input[name='filter${index}-selector'][value='${value}']`) as HTMLInputElement).checked = true;
                    }
                    break;
                case 'multiple':
                    (value as number[]).forEach(v => (group.querySelector(`#chkFilter-${index}-${v}`) as HTMLInputElement).checked = true);
                    break;
            }
        });
    }

    public getFilter(): SearchFilterGroup[] {
        return this._groups.map((g, index) => {
            const { title, name, type } = g.dataset;
            return {
                title,
                name,
                type,
                value: this.getFilterValue(g, index)
            }
        });
    }

    private getFilterValue(group: HTMLDivElement, index: number): number | number[] {
        const { name, type } = group.dataset;
        switch (type) {
            case 'toggle':
                const toggleSelection: number[] = this._toggleSelectors.find(t => t.node.id == `toggle-${name}`).selected;
                return toggleSelection;
            case 'single':
                const selectedElement: HTMLInputElement = group.querySelector(`input[name='filter${index}-selector']:checked`) as HTMLInputElement;
                return  selectedElement ? parseInt(selectedElement.value): null;
            case 'multiple':
                const selectedElements: HTMLInputElement[] = Array.from(group.querySelectorAll('input:checked'));
                return selectedElements.map(e => parseInt((e.closest('.m-search-filter__multiple-item') as HTMLDivElement).dataset.id));
            default:
                return null;
        }
    }

    public deselectGroup(name: string): void {
        const group: HTMLDivElement = this._groups.find(g => g.dataset.name == name);        
        if (name) {            
            const { type } = group.dataset;
            const index: number = this._groups.indexOf(group);
            switch (type) {
                case 'toggle':
                    this._toggleSelectors.find(t => t.node.id == `toggle-${name}`).reset();
                case 'single':
                    const selectedElement: HTMLInputElement = group.querySelector(`input[name='filter${index}-selector']:checked`) as HTMLInputElement;
                    if (selectedElement) {
                        selectedElement.checked = false;
                    }
                case 'multiple':
                    const selectedElements: HTMLInputElement[] = Array.from(group.querySelectorAll('input:checked'));
                    selectedElements.forEach(e => e.checked = false);
                default:
                    return null;
            }
        }
        this._activeFilter = this.getFilter();
    }
}