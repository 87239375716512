import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { SessionService } from '../../../services/session.service';
import { ManageDisciplinesComponent } from '../../molecules/manage-disciplines/manage-disciplines.component';

@Component({
    selector: '.p-last-releases'
})
export class LastReleasesComponent extends ComponentBase<HTMLElement> {

    @Input('lastSeenOn')
    private _lastSeenOn: Date = null;

    @ChildrenRef(HTMLButtonElement, '.p-last-releases__tab')
    private _tabs: HTMLButtonElement[] = null;
    @ChildrenRef(HTMLButtonElement, '.p-last-releases__tab-content')
    private _tabContents: HTMLDivElement[] = null;
    @ChildrenRef(HTMLButtonElement, '.p-last-releases__my-disciplines .o-row__item')
    private _myItems: HTMLDivElement[] = null;
    @ChildrenRef(HTMLButtonElement, '.p-last-releases__all .o-row__item')
    private _allItems: HTMLDivElement[] = null;
    @ChildRef()
    private _manageDisciplines: ManageDisciplinesComponent = null;
    
    public constructor(node: HTMLElement, private _sessionService: SessionService) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        super.onInit(mode);
        this.checkBadge();
    }    

    @ClickListener('#add-disciplines')
    public async onAddDisciplines(): Promise<void> {
        const changed: boolean = await this._manageDisciplines.open();
        if (changed) {
            this.reload();
        }
    }

    @ClickListener('.p-last-releases__tab')
    public onTabSelected(e: MouseEvent): void {
        const tab: HTMLButtonElement = e.currentTarget as HTMLButtonElement;
        this.setTab(tab);
    }

    private checkBadge(): void {
        if (this._sessionService.isSigned && this._lastSeenOn) {
            let myBadge: boolean = false;
            let allBadge: boolean = false;
            if (this._myItems && this._myItems.length) {
                const publishedOns: Date[] = this._myItems.map(i => new Date(i.dataset.publishedOn));
                myBadge = publishedOns.some(d => d > this._lastSeenOn);
                allBadge = myBadge;
            }
            if (!allBadge && this._allItems && this._allItems.length) {
                const publishedOns: Date[] = this._allItems.map(i => new Date(i.dataset.publishedOn));
                allBadge = publishedOns.some(d => d > this._lastSeenOn);
            }
            this.addOrRemoveClass(!myBadge, 'u-hidden', '.p-last-releases__tab-user .p-last-releases__tab-badge');
            this.addOrRemoveClass(!allBadge, 'u-hidden', '.p-last-releases__tab-all .p-last-releases__tab-badge');
        }
    }

    private setTab(tab: HTMLButtonElement): void {
        if (!tab.classList.contains('p-last-releases__tab--selected')) {
            this._tabs.forEach(t => t.classList.remove('p-last-releases__tab--selected'))
            tab.classList.add('p-last-releases__tab--selected');
            
            this._tabContents.forEach(t => {
                 t.classList.add('p-last-releases__tab-content--hidden');
                 t.classList.remove('p-last-releases__tab-content--show')
            });
            const index: number = this._tabs.indexOf(tab);
            this._tabContents[index].classList.add('p-last-releases__tab-content--show');
        }
    }

    public getState(): any {
        const selectedTab: HTMLButtonElement = this._tabs.find(t => t.classList.contains('p-last-releases__tab--selected'));
        const index: number = this._tabs.indexOf(selectedTab);
        return {
            'selected-tab-index': index
        };
    }

    public setState(state: any): void {
        const index: number = state['selected-tab-index'];
        if (index < this._tabs.length) {
            this.setTab(this._tabs[index]);
        }
    }
}