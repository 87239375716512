import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.p-help'
})
export class HelpComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement) {
        super(node);       
    }    
}