import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.o-faqs'
})
export class FaqsComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement) {
        super(node);       
    }

    public onInit(): void {        
    }

    @ClickListener('.o-faqs__question-title')
    public onToggleQuestion(e: MouseEvent): void {
        const questionElement: HTMLDivElement = e.currentTarget as HTMLDivElement;
        const question = questionElement.closest('.o-faqs__question');
        const isExpanded = question.classList.toggle('o-faqs__question--expanded');
        question.setAttribute('aria-expanded', isExpanded.toString());
    }
}