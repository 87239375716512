import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { SessionService } from '../../../services/session.service';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';

const ITEM_MARGIN: number = 24;

@Component({
    selector: '.m-app-menu'
})
export class AppMenuComponent extends ComponentBase<HTMLInputElement> {

    @ChildrenRef(HTMLAnchorElement, '.m-app-menu__item')
    public _items: (HTMLAnchorElement | HTMLButtonElement)[] = null;

    public constructor(node: HTMLInputElement, private _sessionService: SessionService) {
        super(node);

        this.addWindowEventListener('resize', () => this.checkOverflow());
    }

    public onInit(): void {
        super.onInit();
        this.addClass(this._sessionService.isSigned ? 'm-app-menu--signed': 'm-app-menu--unsigned');
        this.setSelected();
    }

    @ClickListener('.m-app-menu__item--dropdown')
    public onToggleDropdown(e: MouseEvent): void {        
        this.toggleDropdown(e.currentTarget as HTMLElement, e);
    }

    private async toggleDropdown(element: HTMLElement, e: MouseEvent): Promise<void> {        
        const arrowElement: HTMLElement = element.querySelector('.u-arrow');
        arrowElement.classList.remove('u-arrow--down');
        arrowElement.classList.add('u-arrow--up');
        const dropdownMenu: DropdownMenuComponent = element.parentElement.querySelector('.m-dropdown-menu').componentRef.ref as DropdownMenuComponent;
        await dropdownMenu.toggle(element, e);
        arrowElement.classList.remove('u-arrow--up');
        arrowElement.classList.add('u-arrow--down');
    }

    public checkOverflow(): boolean {
        const { width } = this._node.getBoundingClientRect();
        let itemsWidth: number = 0;
        const items: HTMLLIElement[] = Array.from(this._node.querySelectorAll('.m-app-menu__item'));
        items.forEach(item => { 
            itemsWidth += item.getBoundingClientRect().width;
        });
        itemsWidth += (items.length - 1) * ITEM_MARGIN;
        const show: boolean = itemsWidth < width;
        if (show) {            
            this.removeClass('m-app-menu--hide');            
        }
        else {
            this.addClass('m-app-menu--hide');            
        }
        //TODO
        // this.dispatchCustomEvent('toogle', { show: itemsWidth < width });
        this._node.dispatchEvent(new CustomEvent('toggle', { detail: { show }}));
        return show;
    }   

    private setSelected(): void {
        const { pathname } = location;
        const selected: HTMLAnchorElement | HTMLButtonElement = this._items.find(i => {
            const path: string = (i as any)?.href?.replace(location.origin,'');
            return ['/', '/home'].includes(pathname) ? ['/', '/home'].includes(path): path == pathname;
        });
        if (selected) {
            selected.classList.add('m-app-menu__item--selected');
        }
    }
}