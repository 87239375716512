import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.o-carrousel-modal'
})
export class CarrouselModalComponent extends ComponentBase<HTMLElement> {

    private _itemElements: HTMLDivElement[] = null;
    private _bulletElements: HTMLButtonElement[] = null;
    private _closeButton: HTMLButtonElement = null;
    private _nextButtons: HTMLButtonElement []= null;

    public constructor(node: HTMLElement) {
        super(node);
    }

    public onInit(): void {
        this._itemElements = Array.from(this.node.querySelectorAll('.o-carrousel-modal__item'));
        this._bulletElements = Array.from(this.node.querySelectorAll('.o-carrousel-modal__bullet'));
        this._closeButton = this.node.querySelector('.o-carrousel-modal__close-button');
        this._nextButtons = Array.from(this.node.querySelectorAll('.o-carrousel-modal__next'));
        this.move(0);
        this._bulletElements?.forEach(b => b.addEventListener('click', () => this.move(parseInt(b.dataset.index))));
        this._nextButtons?.forEach(n => n.addEventListener('click', () => this.onNextClick()));

        if (this._itemElements.length > 1) {
            this._bulletElements.forEach(s => s.style.display = 'block');
            this._nextButtons[this.currentIndex].style.display = 'block';
        }
    }
        
    private move(direction?: 'next' | 'previous' | number): void {
        const index: number = typeof direction === 'number' ? direction: direction == 'next' ? this.nextIndex: this.previousIndex;

        if (this._itemElements.length > 0) {
            this._itemElements.forEach(s => s.dataset.active = 'false');
            const item: HTMLDivElement = this._itemElements[index];
            item.dataset.active = 'true';  
        }
        if (this._bulletElements.length > 0) {
            this._bulletElements.forEach(s => s.dataset.active = 'false');
            const bullet: HTMLButtonElement = this._bulletElements[index];
            bullet.dataset.active = 'true';
        }
        this._closeButton.style.display = 'none';
        if (index === this._itemElements?.length - 1) {
            this._closeButton.style.display = 'block';
        }
        if (this._nextButtons.length) {
            this._nextButtons.forEach(s => s.style.display = 'none');
            this._nextButtons[index].style.display = 'block';
        }
    }

    public onNextClick() {
        if (this.currentIndex === this._itemElements.length - 1) {
            window.dispatchEvent(new CustomEvent('close-carrousel-modal'));
        }
        this.move('next')
    }

    public get currentIndex(): number {
        return parseInt(this._itemElements?.find(s => s.dataset.active === 'true')?.dataset.index);
    }

    public get nextIndex(): number {
        const currentIndex: number = this.currentIndex;
        if (currentIndex < this._itemElements?.length - 1) {
            return currentIndex + 1;
        }
        else {
            return 0;
        }
    }

    public get previousIndex(): number {
        const currentIndex: number = this.currentIndex;
        if (currentIndex > 0) {
            return currentIndex - 1;
        }
        else {
            return this._itemElements?.length - 1;
        }
    }
}