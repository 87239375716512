import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { MouseEnterListener } from '../../../../../lib/web/components/mouse-enter-listener';
import { Dom } from '../../../../../lib/web/core/dom';
import { Str } from '../../../../../lib/web/core/str';
import { TimeLineComponent } from '../../atoms/time-line/time-line.component';
import { VolumneSelectorComponent } from '../../atoms/volume-selector/volume-selector.component';
import { QualityMenuComponent } from '../../atoms/quality-menu/quality-menu.component';
import { AudioMenuComponent } from '../../atoms/audio-menu/audio-menu.component';
import { SessionService } from '../../../services/session.service';
import { CountdownButtonComponent } from '../../atoms/countdown-button/countdown-button.component';
import { RowComponent } from '../../organisms/row/row.component';
import { IconComponent } from '../../atoms/icon/icon.component';
import { DataEventService } from '../../../services/data-event.service';
import { MiniPlayerMenuComponent } from '../../atoms/mini-player-menu/mini-player-menu.component';
import { Agent } from '../../../../../lib/web/core/agent';
import { LogGroup } from '../../../../../lib/web/services/client-log.service';
import { CookieService } from '../../../../../lib/web/services/cookie.service';
import { PlayerSegmentsComponent } from '../player-segments/player-segments.component';
import { ApiService } from '../../../services/api.service';
import { Guid } from '../../../../../lib/web/core/guid';
import { PlaybackRateMenuComponent } from '../../atoms/playbackrate-menu/playbackrate-menu.component';

export interface PlayerMetadata {
    videoId?: string; 
    display?: string;
    currentTime?: number;
    isContinueWatching?: boolean;
    title?: string;
    previous?: {
        url?: string;
        title?: string;
   },   
   next?: {
        url?: string;
        title?: string;
        isComingSoon?: boolean;
   },
   related?: any;
   rating?: any;
   ratingTags?: any[];
   disciplines?: { display: string }[];
   typology?: { display: string };
   closeOnEnd?: boolean;
   progressPercentages?: number[];
   segments?: any[];
   subtitle?: string;
   isUserRequired?: boolean;
}

export interface PlayerOptions {
    controls?: boolean;
    playbackRates?: number[];
    miniPlayer?: 'enable' | 'disable';
    enableFullScreen?: boolean;
    playId?: string;
}

const TRANSITION_TIME: number = 1000;

@Component({
    selector: '.m-player'
})
export class PlayerComponent extends ComponentBase<HTMLInputElement> {

    private _youboraPlugin: any = null;
    private _player: any = null;
    private _hideOverlaysTimeout: NodeJS.Timeout = null;
    private _isFirstPlay: boolean = false;
    private _isLoaded: boolean = false;
    private _metadata: PlayerMetadata = null;
    private _options: PlayerOptions = null;
    private _isWaiting: boolean = false;
    private _showRatingPending: boolean = null;
    private _qualitiesLoaded: boolean = false;
    private _playbackRatesLoaded: boolean = false;
    private _audioLoaded: boolean = false;
    private _castContext: any = null;
    private _isChromecastWaitingObserver: MutationObserver = null;
    private _isChromecastEnded: boolean = null;
    private _progressTracking: Map<number, number> = new Map<number, number>();
    private _lastProgressTracking: number = 0;
    private _playId: string = null;

    @ChildRef('.m-player__container')
    private _containerElement: HTMLDivElement = null;
    @ChildRef('.m-player__overlays')
    private _overlaysElement: HTMLDivElement = null;
    @ChildRef('.m-player__full-screen')
    private _fullScreenElement: HTMLButtonElement = null;
    @ChildRef('.m-player__playbackrate')
    private _playbackRateButton: HTMLButtonElement = null;
    @ChildRef('.m-player__rating')
    private _ratingElement: HTMLDivElement = null;
    @ChildRef('.m-player__rating-alert')
    private _ratingAlertElement: HTMLAudioElement = null;
    @ChildRef('.m-player__chromecast .a-icon')
    private _chromecastIcon: IconComponent = null;
    @ChildRef()
    private _volumeSelector: VolumneSelectorComponent = null;
    @ChildRef()
    private _qualityMenu: QualityMenuComponent = null;
    @ChildRef()
    private _playbackRateMenu: PlaybackRateMenuComponent = null;
    @ChildRef()
    private _audioMenu: AudioMenuComponent = null;
    @ChildRef()
    private _miniPlayerMenu: MiniPlayerMenuComponent = null;
    @ChildRef()
    private _timeLine: TimeLineComponent = null;
    @ChildRef()
    private _nextCountdown: CountdownButtonComponent = null;
    @ChildRef()
    private _relatedRow: RowComponent = null;    
    @ChildRef()
    private _playerSegments: PlayerSegmentsComponent = null;  

    public constructor(node: HTMLInputElement,
        private _sessionService: SessionService, 
        private _cookieService: CookieService,
        private _apiService: ApiService, 
        private _dataEventService: DataEventService) {
            
        super(node);

        this.addWindowEventListener('fullscreenchange', e => {
            this.setFullScreenMode();
        });

        this.addWindowEventListener('resize', () => {
            if (this.isMiniPlayerActive) {
                Dom.removeDraggable(this.node);
            }
        });
    }   

    public onInit(): void {
        super.onInit();        
        this._volumeSelector.addCustomEventListener('input', () => {
            this.setVolume(this._volumeSelector.value);
        });
        this._timeLine.addCustomEventListener('input', () => {
            this.setCurrentTime({ value: this._timeLine.value, action: 'absolute' });
            this.dispatchCustomEvent('user-update-current-time');
        });
        this._qualityMenu.addCustomEventListener('selection-changed', () => {
            this.changeQuality(this._qualityMenu.selectedQuality);
        });
        this._playbackRateMenu?.addCustomEventListener('rate-changed', () => {
            this.changePlaybackRate(this._playbackRateMenu.selectedPlaybackRate);
        });
        this._audioMenu.addCustomEventListener('text-track-changed', () => {
            this.dispatchCustomEvent('text-track-changed');
            if (this._audioMenu.selectedTextTrack && this._audioMenu.selectedTextTrack != 'disabled') {                
                this._dataEventService.textTrackEnabled(this._metadata.videoId, this._metadata.display, this._metadata?.disciplines?.map(d => d.display), this._metadata?.typology?.display, this._audioMenu.selectedTextTrack);
            }            
        });
        this._playerSegments.addCustomEventListener('selected', segment => {
            this.setCurrentTime({ value: segment.position, action: 'absolute' });
            this._dataEventService.selectFragment(this._metadata.videoId, this._metadata.display, 'player', segment.title);
            this.closeSegments();
        });
        this._miniPlayerMenu.addCustomEventListener('mini-player', () => this.toggleMiniPlayer());
        this._miniPlayerMenu.addCustomEventListener('pip', () => this.toggleMiniPlayer({ pip: true }));
        this._nextCountdown.addCustomEventListener('end', () => this.onNextChapter());
        if (!document.pictureInPictureEnabled) {
            this._node.dataset.pipDisabled = 'true';
        }
    }
   
    @ClickListener('.m-player__play')
    public onPlay(): void {
        this._player.play();
        this.setHideOverlaysTimeout();
    }

    @ClickListener('.m-player__pause') 
    public onPause(): void {
        this._player.pause();
        this.setHideOverlaysTimeout();
    }

    @ClickListener('.m-player__rewind') 
    public onRewind(): void {
        this.setCurrentTime({ value: -10, action: 'relative' });        
        this.setHideOverlaysTimeout();
        this.dispatchCustomEvent('user-update-current-time');
    }

    @ClickListener('.m-player__forward') 
    public onForward(): void {
        this.setCurrentTime({ value: 10, action: 'relative' });
        this.setHideOverlaysTimeout();
        this.dispatchCustomEvent('user-update-current-time');
    }

    @ClickListener('.m-player__back') 
    public onBack(): void {
        this.dispatchCustomEvent('back');
    }

    @ClickListener('.m-player__close-mini-player') 
    public onCloseMiniPlayer(): void {
        this.closeMiniPlayer();
    }

    @ClickListener('.m-player__expand-mini-player') 
    public onExpandMiniPlayer(): void {
        this.toggleMiniPlayer();
    }

    @ClickListener('.m-player__full-screen') 
    public onToggleFullScreen(): void {
        Dom.toggleFullScreen(Agent.iOS() ? this._node.querySelector('video'): null);
        this.setHideOverlaysTimeout();
    }

    @ClickListener('.m-player__volume') 
    public onToggleVolume(e: MouseEvent): void {
        e.stopPropagation();
        this.setVolume(this._player.muted() ? 'on': 'off');                            
        this.setHideOverlaysTimeout();
    }

    @ClickListener('.m-player__quality') 
    public onToggleQuality(e: MouseEvent): void {
        e.stopPropagation();
        this.openMenu('quality');
    }

    @ClickListener('.m-player__playbackrate') 
    public onTogglePlaybackRate(e: MouseEvent): void {
        e.stopPropagation();
        this.openMenu('playbackRate');
    }

    @ClickListener('.m-player__audio') 
    public onToggleAudio(e: MouseEvent): void {
        e.stopPropagation();
        this.openMenu('audio');
    }

    @ClickListener('.m-player__mini-player') 
    public onToggleMiniPlayer(e: MouseEvent): void {
        e.stopPropagation();
        this.toggleMiniPlayer({ pip: true });
    }

    @ClickListener('.m-player__pip') 
    public onPip(e: MouseEvent): void {
        e.stopPropagation();
        this.requestPictureInPicture();        
    }

    @ClickListener('.m-player__chromecast') 
    public onChromecast(e: MouseEvent): void {
        e.stopPropagation();
        const chromecastButton: HTMLButtonElement = document.querySelector('.vjs-chromecast-receiver-button');
        if (chromecastButton) {
            chromecastButton.click();
        }
    }

    @ClickListener('.m-player__previous-chapter') 
    public onPreviousChapter(): void {
        this.dispatchCustomEvent('previous-chapter', this._metadata.previous);
    }

    @ClickListener('.m-player__next-chapter') 
    public onNextChapter(): void {
        this.dispatchCustomEvent('next-chapter', this._metadata.next);
    }

    @ClickListener('.m-player__next-overlay button')
    public onNextCountdown(): void {
        this.onNextChapter();
    }

    @ClickListener('.m-player__segments')
    public onShowSegments(): void {
        this.showSegments();
    }

    @ClickListener('.m-player__close-segments')
    public onCloseSegments(): void {
        this.closeSegments();
    }

    @EventListener('mousemove')
    public onMouseMove(): void {
        this.showOverlays();
        this.setHideOverlaysTimeout();
    }

    @MouseEnterListener('.m-player__volume')
    public onMouseEnter(): void {
        if (!this.isTouch) {
            this.openMenu('volume');
        }
    }

    @ClickListener('.m-player__overlays')
    public onClickOverlays(e: MouseEvent): void {
        if ((e.target as HTMLElement).closest('.a-audio-menu') == null && (e.target as HTMLElement).closest('a-quality-menu') == null && (e.target as HTMLElement).closest('a-volume-selector') == null ) {
            this.openMenu(null);
        }
    }    

    @ClickListener()
    public onClick(): void {
        if (this.isTouch) {
            this.showOverlays();
            this.setHideOverlaysTimeout();
        }
    }

    private reset(metadata: PlayerMetadata, options?: PlayerOptions): void {
        this._progressTracking.clear();
        this._lastProgressTracking = 0;
        this._metadata = metadata;
        this._options = options;
        const { previous, next, title, segments } = this._metadata;
        delete this._node.dataset.status;
        delete this._node.dataset.firstPlay;
        this.hideOverlays();
        this._isFirstPlay = false;
        this.setInnerHTML(title, '.m-player__title')
        this.addOrRemoveClass(!previous?.url && !next?.url, 'u-hidden-important', '.m-player__chapters-navigation');
        this.addClass('u-hidden', '.m-player__mini-player-control');
        if (previous?.url) {
            this.enable('.m-player__previous-chapter');
        }
        else {
            this.disable('.m-player__previous-chapter');
        }
        if (next?.url && !next.isComingSoon) {
            this.enable('.m-player__next-chapter');
            this.setNext();
        }
        else {
            this.disable('.m-player__next-chapter');
        }
        this.addOrRemoveClass(!(segments?.length > 0), 'u-hidden', '.m-player__segments');
        this._playerSegments.set(segments);
        this._ratingElement.innerHTML = this.rating?.code;
        this._ratingElement.style.backgroundColor = this.rating?.color;   
        this.setInnerHTML((this.ratingTags || []).map((t, i) => i == 0 ? t.name: t.name.toLowerCase()).join(', '), '.m-player__rating-tags');
    }   

    public load(metadata: PlayerMetadata, options?: PlayerOptions): void {
        this.dataset('videoId', metadata.videoId);
        this._playId = options.playId;

        if (!window.isBrightcovePlayerScriptLoaded) {      
            this.loadPlayerScript();
        }
        if (!window.isYouboraPluginScriptLoaded) {      
            this.loadYouboraScript();
        }
        if (options.enableFullScreen !== false) {
            Dom.onFullScreen();
            setTimeout(() => {
                if (!Dom.isFullScreen() && Agent.iOS()) {
                    Dom.onFullScreen(this._node.querySelector('video'));
                }
            }, 10);
        }
        this.reset(metadata, options);

        this.logPlayerMetadata(this._playId, 'afterReset');
        
        const { videoId } = metadata;
        const { controls } = options || {};
        const videoJsElement: HTMLElement = document.createElement('video-js');
        videoJsElement.id = 'player'
        videoJsElement.dataset.account = CONFIG.BRIGHTCOVE_ACCOUNT;
        videoJsElement.dataset.player = CONFIG.BRIGHTCOVE_PLAYER;
        videoJsElement.dataset.embed = 'default';
        videoJsElement.dataset.videoId = videoId;
        if (controls !== false) {
            videoJsElement.dataset.controls = '';
        }
        this._containerElement.appendChild(videoJsElement);   
        
        this.logPlayerMetadata(this._playId, 'afterVideoJs');

        if (window.isBrightcovePlayerScriptLoaded) {
            logger.notice('🎥 player script already loaded');
            this.onPlayerLoaded();
        }
        else {
            logger.notice('🎥 loading player script');
            this.loadPlayerScript(() => this.onPlayerLoaded());            
        }
        this.logPlayerMetadata(this._playId, 'endLoad');
    }
   
    private onPlayerLoaded(): void {
        this.logPlayerMetadata(this._playId, 'playerLoaded');

        this._player = bc('player');

        this.addCustomEventListener('enterpictureinpicture', () => {
            this._node.dataset.pip = 'true';
            if (!this.isMiniPlayerActive) {
                this.toggleMiniPlayer();
            }
        }, 'video');
        this.addCustomEventListener('leavepictureinpicture', () => {
            delete this._node.dataset.pip;
            if (this.isMiniPlayerActive) {
                this.toggleMiniPlayer();      
            }      
        }, 'video');

        if (window.isYouboraPluginScriptLoaded) {
            logger.notice('🎥 youbora script already loaded');
            this.onYouboraLoaded();
        }
        else {
            this.loadYouboraScript(() => this.onYouboraLoaded());
        }
        this._isLoaded = true;
        this._player.on('touchstart', () => {
            this.onClick();
        });
        this._player.on('timeupdate', (e: any) => {
            this.setInnerHTML(Str.getProgress(this._player.currentTime(), this._player.duration()), '.m-player__progress');
            const currentTime: number = this._player.currentTime();
            this._timeLine.value = currentTime;
            this.onProgressTracking(currentTime);
        }); 
        this._player.on('loadedmetadata', async (e: any) => {
            this._timeLine.max = this._player.duration();                                    
            this.tryPlay();
            if (this._isChromecastEnded) {
                this._isChromecastEnded = false;
                this.reloadAudios();
            }
        });        
        this._player.on('firstplay', (e: any) => {
            this.setVolume(this._player.muted() ? 'off': this._player.volume());
            this._node.dataset.firstPlay = 'true';            
            this._node.dataset.status = 'playing';
            this._isFirstPlay = true;
            const { currentTime } = this._metadata;
            if (currentTime) {
                this.setCurrentTime({ value: currentTime, action: 'absolute'});
            }
        });
        this._player.on('play', (e: any) => {
            this._node.dataset.status = 'playing';
            this.showOverlays();        
            this.setHideOverlaysTimeout();
            if (this._showRatingPending == null) {
                this._showRatingPending = true;
            }
        });
        this._player.on('pause', (e: any) => {
            this._node.dataset.status = 'paused';
            this.setHideOverlaysTimeout();
            this.dispatchCustomEvent('pause');
        });

        // Showing loader events
        this._player.on('waiting', (e: any) => {
            this.onIsWaitingChanged(true);
        });
        this._player.on('seeking', (e: any) => {
            this.onIsWaitingChanged(true);
        });
        // Hiding loader events
        this._player.on('seeked', (e: any) => {
            this.onIsWaitingChanged(false);
        });
        this._player.on('canplay', (e: any) => {
            this.onIsWaitingChanged(false);
            this.addQualities();
            this.addPlaybackRates();
            this.addAudios();            
            this.setupChromecast();
            this.setOptions();
        });
        this._player.on('canplaythrough', (e: any) => {
            this.onIsWaitingChanged(false);
        });
        this._player.on('playing', (e: any) => {
            this.onIsWaitingChanged(false);
        });
        this._player.on('ended', (e: any) => {
            if (this.isMiniPlayerActive) {
                this.closeMiniPlayer();
            }
            else if (this._metadata.closeOnEnd) {
                this.closePlayer();
            }
            else {
                this._node.dataset.status = 'ended';
                this._timeLine.value = this._timeLine.max;
                this.onIsWaitingChanged(false);
                this.showOverlays();
                this.dispatchCustomEvent('ended');
            }
        });
        this.logPlayerMetadata(this._playId, 'endPlayerLoaded');
    }

    public youboraUpdateOption(key: string, value: string) {
        if (typeof youbora !== 'undefined' && this._youboraPlugin?.options) {
            this._youboraPlugin.options[key] = value;
        }
    }

    private onYouboraLoaded(): void {        
        this.logPlayerMetadata(this._playId, 'startYoubora');

        if (typeof youbora !== 'undefined') {
            logger.notice('🎥 initializing youbora plugin');
            this._youboraPlugin = new youbora.Plugin({ accountCode: CONFIG.YOUBORA_ACCOUNT_CODE });            

            this.logPlayerMetadata(this._playId, 'youbora');

            const { user } = this._sessionService.get() || {};

            let options: any = {
                accountCode: CONFIG.YOUBORA_ACCOUNT_CODE,
                username: user?.id,
                'content.transactionCode': CONFIG.YOUBORA_TRANSACTION_CODE,
                'content.title': this.videoId,
                'content.isLive': false,
                'extraparam.1': 'web',
                'extraparam.2': `${this._player?.playbackRate()}x` || '1x',
                'extraparam.3': this._playId,
            };
            const { isContinueWatching, isUserRequired } = this._metadata || {};
            if (isContinueWatching) {
                options = {
                    ...options,
                    'content.playbackType': 'catch-up'
                };
            }
            logger.log('🎥 youbora options', options);
            this.logYouboraPlay(options);
            if (!user?.id && isUserRequired) {
                this.logYouboraPlayerUser(user);
            }
            this._youboraPlugin.setOptions(options);
            this._youboraPlugin.setAdapter(new youbora.adapters.Videojs(this._player));
            this.logPlayerMetadata(this._playId, 'endYoubora');
        }
    }
   
    private async logPlayerMetadata(playId: string, step: string): Promise<void> {
        if (!this.metadataVideoId) {
            this._apiService.post(`/api/v1/capsule/log-player-metadata/${playId}/${step}`, {
                body: this._metadata
            });
        }
    }

    private async logYouboraPlay(options: any): Promise<void> {
        this._apiService.post(`/api/v1/capsule/log-youbora-play`, {
            body: options
        });
    }

    private async logYouboraPlayerUser(options: any): Promise<void> {
        this._apiService.post(`/api/v1/capsule/log-youbora-player-user`, {
            body: options
        });
    }

    private async tryPlay(): Promise<void> {
        if (this._node.dataset.status != 'playing') {
            if (!(await this.autoplay())) {
                this.showOverlays();
            }
        }
    }

    private async autoplay(): Promise<boolean> {
        return new Promise<boolean>(async (resolve: (value: boolean) => void) => {            
            setTimeout(() => {
                if (this._node.dataset.status != 'playing') {
                    resolve(false);
                }
            }, 2000);
            try {
                this._player.play().then(() => {
                    resolve(true);
                }).catch(async () => {
                    try {
                        if (!this._player.muted()) {
                            this.setVolume('off');
                            if (await this.autoplay()) {
                                resolve(true);
                            }     
                        }   
                        else {
                            resolve(false);
                        }   
                    }
                    catch (e: any) {
                        resolve(false);
                    }
                });
            }
            catch (e: any) {
                resolve(false);
            }
        });
    }

    private setCurrentTime(time: { value?: number, action?: 'absolute' | 'relative' }): void {
        const { value, action } = time;
        let targetTime: number = action == 'absolute' ? value: (this._player.currentTime() || 0) + value;
        if (targetTime < 0) {
            targetTime = 0;
        }
        else if (targetTime > this._player.duration()) {
            targetTime = this._player.duration();
        }
        this._player.currentTime(targetTime);
    }

    private setVolume(volume: number | 'on' | 'off'): void {
        volume = volume || 0;
        if (typeof volume == 'number') {
            this._player.volume(volume);
            if (this._player.volume() > 0) {
                this._player.muted(false);
                if (this._volumeSelector.value != this._player.volume()) {
                    this._volumeSelector.value = this._player.volume();
                }     
            }
        }
        else if (volume == 'off') {
            this._player.muted(true);
        }
        else if (volume == 'on') {
            this._player.muted(false);  
            if (this._player.volume() == 0) {
                this._player.volume(1);
            }
            if (this._volumeSelector.value != this._player.volume()) {
                this._volumeSelector.value = this._player.volume();
            }
        }        
        if (this._player.volume() == 0 || this._player.muted()) {
            this.addClass('m-player__volume--muted', '.m-player__volume');
            this._player.muted(true);
            if (this._volumeSelector.value > 0) {
                this._volumeSelector.value = 0;
            }
        }
        else {
            this.removeClass('m-player__volume--muted', '.m-player__volume');            
        }
    }

    private addQualities(): void {
        if (!this._qualitiesLoaded) {
            this._qualitiesLoaded = true;
            const qualityLevels: { selectedIndex_: number ,levels_: any[] } = this._player.qualityLevels();
            if (qualityLevels && qualityLevels.levels_ && qualityLevels.levels_.length) {
                this._qualityMenu.selectedQuality = (qualityLevels.levels_[qualityLevels.selectedIndex_] || {}).id;
                this._qualityMenu.qualities = qualityLevels.levels_.filter((quality: {id: string, height: number}) => quality.height).map((quality: {id: string, height: number}, index: number) => {
                    return {
                        id: quality.id, 
                        display: quality.height.toString(),
                        isSelected: qualityLevels.selectedIndex_ === index
                    };
                });
            }
            if ((this._qualityMenu.qualities || {}).length > 1) {
                this.removeClass('u-hidden', '.m-player__quality-control');
            }
        }
    }

    private changeQuality(qualityId: string): void {
        const qualityLevels = this._player.qualityLevels().levels_;
        qualityLevels.forEach((quality: {id: string, enabled: boolean}) => {
            if (quality.id === qualityId) {
                quality.enabled = true;
            }
            else {
                quality.enabled = false;
            }
        });
    }

    private addPlaybackRates(): void {
        if (!this._playbackRatesLoaded) {
            this._playbackRatesLoaded = true;
            const playbackRates: number[] = this._player.playbackRates();
            if (playbackRates && playbackRates.length) {
                this._playbackRateMenu.playbackRates = playbackRates.map((id: number) => {
                    const isSelected = id == 1;
                    if (isSelected) {
                        this._playbackRateButton.innerText = id + 'x';
                    }
                    return { id: id.toString(), isSelected };
                });
            }
            if ((this._playbackRateMenu.playbackRates || {}).length > 1) {
                this.removeClass('u-hidden', '.m-player__playbackrate-control');
            }
        }
    }

    private changePlaybackRate(playbackRateId: string): void {
        this._playbackRateMenu.playbackRates.forEach(({id, isSelected}) => {
            if (id === playbackRateId) {
                isSelected = true;
                this._player.playbackRate(playbackRateId);
                const display = id + 'x';
                this._playbackRateButton.innerText = display;
                this.youboraUpdateOption('content.customDimension.2', display);
            }
            else {
                isSelected = false;
            }
        });
    }

    private addAudios(): void {
        if (!this._audioLoaded) {
            this._audioLoaded = true;
            const audioTracks: { tracks_: { enabled: boolean, language: string , id: string }[] } = this._player.audioTracks();
            const textTracks: { tracks_: { id: string, mode: 'disabled' | 'hidden' | 'showing', kind: string, label: string, language: string }[] } = this._player.textTracks();

            this._audioMenu.addAudioTracks(audioTracks.tracks_);
            this._audioMenu.addTextTracks(textTracks.tracks_);

            if (this._metadata.subtitle) {
                this._audioMenu.selectTextTrackLanguage(this._metadata.subtitle);
            }

            // if (audioTracks.tracks_.length > 1 && textTracks.tracks_.length > 0) {
                this.removeClass('u-hidden', '.m-player__audio-control');
            // }                
        }
    }

    private reloadAudios(): void {
        this._audioLoaded = false;
        this._audioMenu.unload();
        this.addAudios(); 
    }   

    private onProgressTracking(currentTime: number): void {
        this._progressTracking.set(Math.floor(currentTime), Math.floor(currentTime));
        const currentProgress: number = Math.floor((Array.from(this._progressTracking.keys()).length / this.duration) * 100);
        if (this._lastProgressTracking != currentProgress) {
            this._lastProgressTracking = currentProgress;
            if ((this._metadata.progressPercentages || []).includes(this._lastProgressTracking)) {
                this.dispatchCustomEvent('percentage-seen', { percentage: this._lastProgressTracking });
            }
        }        
    }

    private setupChromecast(): void {
        if (window.cast?.framework && !this._castContext) {            
            logger.notice('📺 Setting up chromecast plugin');
            this.stopChromecast();            
            this._player.chromecastReceiver().options.appName = USER_LOCALE.siteName;
            const context: any = window.cast?.framework?.CastContext?.getInstance();
            if (context) {
                this._castContext = context;
                this._castContext.addEventListener(window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED, this.chromecastSessionStateChangeHandler);
                this._castContext.addEventListener(window.cast.framework.CastContextEventType.CAST_STATE_CHANGED, this.chromecastCastStateChangeHandler);
                this.setChromecastState();
            }
        }
        else if (this._castContext) {
            this.setChromecastState();
        }
    }    

    private setChromecastState(): void {
        if (this._castContext) {
            logger.notice(new LogGroup('📺 Setting chromecast state'));
            this.chromecastSessionStateChangeHandler({ sessionState: this._castContext.getSessionState() });
            this.chromecastCastStateChangeHandler({ castState: this._castContext.getCastState() });    
            logger.closeGroup();
        }
    }

    private stopChromecast(): void {
        if (window.cast && window.cast.framework && window.cast.framework.CastContext && window.cast.framework.CastContext.getInstance() && window.cast.framework.CastContext.getInstance().getCurrentSession()) {
            window.cast.framework.CastContext.getInstance().getCurrentSession().endSession(true);
            this._chromecastIcon.key = 'chromecast-not-connected'; 
            if (this._castContext) {
                this._castContext.removeEventListener(window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED, this.chromecastSessionStateChangeHandler);
                this._castContext.removeEventListener(window.cast.framework.CastContextEventType.CAST_STATE_CHANGED, this.chromecastCastStateChangeHandler);
                this._castContext = null;
            }
            this._isChromecastEnded = false;
            if (this._isChromecastWaitingObserver) {
                this._isChromecastWaitingObserver.disconnect();
                this._isChromecastWaitingObserver = null;
            }
        }
    }

    private chromecastSessionStateChangeHandler = (event: any) => {                                         
        const { NO_SESSION, SESSION_ENDED, SESSION_ENDING, SESSION_RESUMED, SESSION_STARTED, SESSION_STARTING, SESSION_START_FAILED } = window.cast.framework.SessionState;
        logger.notice(`📺 session: ${event.sessionState}`);
        switch (event.sessionState) {
            case SESSION_ENDING:
                this._isChromecastEnded = true;
            case SESSION_ENDED:
            case SESSION_START_FAILED:
            case NO_SESSION:                        
                this.onIsChromcastWaiting(false);
                break;
            case SESSION_STARTED:
                this.onIsChromcastWaiting(true);
                this._dataEventService.shareStreaming(this._metadata.videoId, this._metadata.display, this._metadata?.disciplines?.map(d => d.display), this._metadata?.typology?.display, 'detail', 'Chromecast');
                break;
        }
    }

    private chromecastCastStateChangeHandler  = (event: any) => {
        const { CONNECTED, CONNECTING, NOT_CONNECTED, NO_DEVICES_AVAILABLE } = window.cast.framework.CastState;
        logger.notice(`📺 cast: ${event.castState}`);
        switch (event.castState) {
            case CONNECTED:
                this.removeClass('u-hidden', '.m-player__chromecast-control');
                this._chromecastIcon.key = 'chromecast-connected';                
                break;
            case CONNECTING:
                this.removeClass('u-hidden', '.m-player__chromecast-control');
                this._chromecastIcon.key = 'chromecast-connected';
                break;
            case NOT_CONNECTED:
                this.removeClass('u-hidden', '.m-player__chromecast-control');
                this._chromecastIcon.key = 'chromecast-not-connected';
                break;
            case NO_DEVICES_AVAILABLE:
                this.addClass('u-hidden', '.m-player__chromecast-control');
                this._chromecastIcon.key = 'chromecast-not-connected';
                break;
        }
    }
  
    private setFullScreenMode(): void {
        const isFullScreen: boolean = Dom.isFullScreen();
        this.addOrRemoveClass(isFullScreen, 'm-player__full-screen--on', '.m-player__full-screen');
        this._fullScreenElement.dataset.tooltip = isFullScreen ? USER_LOCALE.exitFullScreen: USER_LOCALE.fullScreen;
        if (isFullScreen) {
            Dom.lockOrientation('landscape');
        }
        else {
            Dom.unlockOrientation();
        }
    }

    private loadPlayerScript(callback?: () => void): void {
        if (callback) {
            this.logPlayerMetadata(this._playId, 'beforePlayerScript');
        }
        const scriptElement: HTMLScriptElement = document.createElement('script');
        scriptElement.setAttribute('data-ot-ignore', '');
        scriptElement.onload = () => { 
            window.isBrightcovePlayerScriptLoaded = true;
            if (callback) {
                this.logPlayerMetadata(this._playId, 'afterPlayerScript');
                callback();                
            }
        };
        scriptElement.src = `https://players.brightcove.net/${CONFIG.BRIGHTCOVE_ACCOUNT}/${CONFIG.BRIGHTCOVE_PLAYER}_default/index.min.js`;
        this._containerElement.appendChild(scriptElement);
    }

    private loadYouboraScript(callback?: () => void): void {
        const scriptElement: HTMLScriptElement = document.createElement('script');
        scriptElement.setAttribute('data-ot-ignore', '');
        scriptElement.onload = () => { 
            window.isYouboraPluginScriptLoaded = true;
            if (callback) {
                callback();
            }
        };
        scriptElement.src = `${CONFIG.STORAGE_URL}/assets/js/youbora-6.7.7.min.js`;
        this._containerElement.appendChild(scriptElement);
    }

    private onIsWaitingChanged(isWaiting: boolean): void {
        this._isWaiting = isWaiting;
        if (this._isWaiting) {
            if (this._overlaysElement.classList.contains('m-player__overlays--show')) {
                this.addClass('u-hidden-important', '.m-player__loader');
                this.removeClass('u-hidden-important', '.m-player__overlay-loader');
            }
            else {
                this.removeClass('u-hidden-important', '.m-player__loader');
                this.addClass('u-hidden-important', '.m-player__overlay-loader');
            }
        }
        else {
            this.addClass('u-hidden-important', '.m-player__loader');
            this.addClass('u-hidden-important', '.m-player__overlay-loader');
        }
    }

    private onIsChromcastWaiting(isWaiting: boolean): void {
        this._node.dataset.isChromecastWaiting = isWaiting.toString();
        if (isWaiting) {
            this.hideOverlays();
            this.onIsWaitingChanged(true);
        }
        if (isWaiting && !this._isChromecastWaitingObserver) {
            this._isChromecastWaitingObserver = new MutationObserver(() => {
                if (document.querySelector('.vjs-chromecast-receiver-overlay')) {
                    setTimeout(() => {
                        this.reloadAudios();
                        this.onIsChromcastWaiting(false); 
                        this.showOverlays();   
                    }, 1000);
                    this._isChromecastWaitingObserver.disconnect();
                    this._isChromecastWaitingObserver = null;                    
                }
            });
            this._isChromecastWaitingObserver.observe(this._node, {
                childList: true,
                subtree: true
            });
        }        
    }

    private showRating(): void {  
        if (!this.isOverlaysShown && this._metadata && this._metadata.rating && this._node.dataset.isChromecastWaiting !== 'true' && this._node.dataset.segmentsOpened !== 'true') {
            this._overlaysElement.classList.add('m-player__overlays--show-rating');
            this._showRatingPending = false;
            if (this._metadata.rating?.age >= 16) {
                this._ratingAlertElement.play();
            }
        }
    }
    
    private showSegments(): void {
        if (this._metadata && this._metadata.segments) {
            this._playerSegments.show();
            this._overlaysElement.classList.add('m-player__overlays--show-segments');
            this._node.dataset.segmentsOpened = 'true';
            this._dataEventService.openFragment(this._metadata.videoId, this._metadata.display, 'player');
        }
    }

    private closeSegments(): void {
        if (this._metadata && this._metadata.segments) {            
            this._overlaysElement.classList.add('m-player__overlays--hide-segments');
            setTimeout(() => {
                this._overlaysElement.classList.remove('m-player__overlays--hide-segments', 'm-player__overlays--show-segments');
                delete this._node.dataset.segmentsOpened;
            }, 500);            
        }
    }

    private showOverlays(): void {
        if (this._metadata) {
            if (this._node.dataset.isChromecastWaiting !== 'true' && this._node.dataset.segmentsOpened !== 'true') {
                this._overlaysElement.classList.remove('m-player__overlays--show-rating');
                if (this._node.dataset.status != 'ended') {
                    this._overlaysElement.classList.add('m-player__overlays--show');
                    this.onIsWaitingChanged(this._isWaiting);
                }
                else {
                    if (!this._overlaysElement.classList.contains('m-player__overlays--show-next') && !this._overlaysElement.classList.contains('m-player__overlays--show-related')) {
                        const { next, related } = this._metadata;
                        if (next && !next.isComingSoon) {
                            this._overlaysElement.classList.add('m-player__overlays--show-next');
                            this._nextCountdown.start();
                        }
                        else if (related) {
                            related.viewAllClick = () => {  
                                this.closePlayer();
                            };
                            related.itemClick = () => {
                                this.closePlayer();
                            };
                            this._relatedRow.loadData(related);
                            this._overlaysElement.classList.add('m-player__overlays--show-related');
                        }
                        else {
                            this._overlaysElement.classList.add('m-player__overlays--show');
                            this.onIsWaitingChanged(this._isWaiting);
                        }
                    }
                }
            }
        }
    }

    private hideOverlays(force?: boolean): void {
        if (force || (this._isFirstPlay && this._node.dataset.status != 'ended')) {
            this.hideTooltips();
            this._overlaysElement.classList.remove('m-player__overlays--show', 'm-player__overlays--show-rating', 'm-player__overlays--show-next', 'm-player__overlays--show-related');
            this._overlaysElement.classList.remove('m-player__overlays--playback');
            this._qualityMenu.hide();
            this._playbackRateMenu?.hide();
            this._audioMenu.hide();
            this.onIsWaitingChanged(this._isWaiting);
        }
        if (this._showRatingPending) {
            setTimeout(() => {
                this.showRating();
                this.setHideOverlaysTimeout();
            }, 2000);
        }
    }

    private setHideOverlaysTimeout(): void {
        if (this._hideOverlaysTimeout) {
            clearTimeout(this._hideOverlaysTimeout);
            this._hideOverlaysTimeout = null;
        }
        this._hideOverlaysTimeout = setTimeout(() => {
            this.hideOverlays();
        },  CONFIG.PLAYER_INACTIVITY_TIMEOUT);
    }    

    private openMenu(menu: 'volume' | 'audio' | 'quality' | 'mini-player' | 'playbackRate'): void {
        this.hideTooltips();
        this._volumeSelector.hide();
        if (menu != 'audio') {
            this._audioMenu.hide();
        }
        if (menu != 'quality') {
            this._qualityMenu.hide();
        }
        if (menu != 'playbackRate') {
            this._playbackRateMenu?.hide();
        }
        if (menu != 'mini-player') {
            this._miniPlayerMenu.hide();
        }
        if (menu == 'volume') {
            this._volumeSelector.show();            
        }
        if (menu == 'audio') {
            this._audioMenu.toggle();
        }
        if (menu == 'quality') {     
            this._qualityMenu.toggle();       
        }
        if (menu == 'playbackRate') {     
            this._playbackRateMenu.toggle();       
        }
        if (menu == 'mini-player') {
            this._miniPlayerMenu.toggle();
        }
        this.setHideOverlaysTimeout();
    }

    private setNext(): void {
        const { next } = this._metadata;
        if (next) {
            this.setInnerHTML(next.title, '.m-player__next-title');
        }
    }

    private setOptions() {
        const canOpenMiniPlayer: boolean = (this._options || {}).miniPlayer !== 'disable';
        if (canOpenMiniPlayer && !Agent.iPhone() && document.pictureInPictureEnabled) {
            this.removeClass('u-hidden', '.m-player__mini-player-control');
        }
        else {
            this.addClass('u-hidden', '.m-player__mini-player-control');
        }
    }
    
    public get isMiniPlayerActive(): boolean {
        return this._node.dataset.miniplayer === 'true';
    }

    private setMiniPlayerActive(value: boolean, pip?: boolean): void {
        if (value) {
            this._node.dataset.miniplayer = 'true'; 
        } 
        else {        
            delete this._node.dataset.miniplayer;
            this.exitPictureInPicture();
        }
    }

    private requestPictureInPicture(): void {
        if (document.pictureInPictureEnabled) {
            this._node.querySelector('video').requestPictureInPicture();
        }        
    }

    private exitPictureInPicture(): void {
        if (document.pictureInPictureEnabled && document.pictureInPictureElement) {
            document.exitPictureInPicture();
        }        
    }

    private toggleMiniPlayer(options?: { closing?: boolean, pip?: boolean }): void {
        const { closing, pip } = options || {};
        const OVERLAYS_DISABLED_CLASS: string = 'm-player__overlays--disabled';
        if (this.isMiniPlayerActive) {
            this._overlaysElement.classList.add(OVERLAYS_DISABLED_CLASS);
            this.setMiniPlayerActive(false);
            delete this._node.dataset.notransitions;
            Dom.removeDraggable(this._node);
            this.hideBodyOverflow();
            if (closing) {
                this._overlaysElement.classList.remove(OVERLAYS_DISABLED_CLASS);
                this.dispatchCustomEvent('mini-player-deactivated', { closing });
            }
            else {
                setTimeout(() => {
                    this._overlaysElement.classList.remove(OVERLAYS_DISABLED_CLASS);
                    this.dispatchCustomEvent('mini-player-deactivated', { closing });
                }, TRANSITION_TIME);
            }
        }
        else {
            const isFullScreen: boolean = Dom.isFullScreen();
            isFullScreen && this.setFullScreenMode();
            Dom.offFullScreen();
            Dom.unlockOrientation();
            this._overlaysElement.classList.add(OVERLAYS_DISABLED_CLASS);
            this.setMiniPlayerActive(true);
            this.showBodyOverflow();
            setTimeout(() => {
                this._node.dataset.notransitions = 'true';
                Dom.makeDraggable(this._node);
                this._overlaysElement.classList.remove(OVERLAYS_DISABLED_CLASS);
                this.dispatchCustomEvent('mini-player-activated');
                if (pip) {
                    this.requestPictureInPicture();
                }
            }, TRANSITION_TIME);
        }
        this.hideTooltips();
    }

    public closeMiniPlayer(): void {
        if (this.isMiniPlayerActive) {
            this.toggleMiniPlayer({ closing: true });
            this.closePlayer();
        }
    }

    private closePlayer(): void {
        this.dispatchCustomEvent('player-close');
    }

    public hide(): void { 
        this.stopChromecast();
        this._showRatingPending = null; 
        this.hideTooltips();  
        this.dispatchCustomEvent('hidding');
        this._nextCountdown.stop();
        this._volumeSelector.hide();
        this._audioMenu.hide();
        this._qualityMenu.hide();
        this._playbackRateMenu?.hide();
        this.hideOverlays(true);
        if (this._hideOverlaysTimeout) {
            clearInterval(this._hideOverlaysTimeout);
            this._hideOverlaysTimeout = null;
        }      
        if (this._player)   {
            this._player.pause();
        }
        Dom.offFullScreen();
        Dom.unlockOrientation();
    }

    public unload(hide: boolean = true): void {
        if (hide) {
            this.hide();
            Dom.offFullScreen();            
        }
        else {
            this.hideOverlays(true);
            this.onIsWaitingChanged(true);
        }
        if (this.isMiniPlayerActive) {
            this.closeMiniPlayer();
        }
        this._metadata = null;
        this._isFirstPlay = false;
        this._qualitiesLoaded = false;
        this._playbackRatesLoaded = false;
        this._qualityMenu.unload();
        this._playbackRateMenu?.unload();
        this._audioLoaded = false;
        this._audioMenu.unload();
        if (this._player) {            
            this._player.dispose();
            this._player = null;
        }
        this._isLoaded = false;
    }

    public dispose(): void {
        this.unload();
        super.dispose();
    }

    private get isOverlaysShown(): boolean {
        return this._overlaysElement.classList.contains('m-player__overlays--show')
            || this._overlaysElement.classList.contains('m-player__overlays--show-next')
            || this._overlaysElement.classList.contains('m-player__overlays--show-related')
            || this._overlaysElement.classList.contains('m-player__overlays--show-rating');
    }

    public get isLoaded(): boolean {
        return this._isLoaded;
    }   

    public get videoId(): string {
        return this.node.dataset.videoId;
    }

    public get metadataVideoId(): string {
        return this._metadata?.videoId;
    }

    public get rating(): any {
        return this._metadata?.rating;
    }

    public get ratingTags(): any[] {
        return this._metadata?.ratingTags;
    }

    public get currentTime(): number {
        return this._player?.currentTime();
    }

    public get duration(): number {
        return this._player?.duration();
    }

    public get isFirstPlay(): boolean {
        return this._isFirstPlay;
    }

    public get selectedTextTrackLanguage(): string {
        return this._audioMenu?.selectedTextTrackLanguage;
    } 

    public setDisposable(value: boolean): void {
        this.isDisposable = value;
        this._timeLine.isDisposable = value;
        this._volumeSelector.isDisposable = value;
        this._audioMenu.isDisposable = value;
        this._qualityMenu.isDisposable = value;
        this._playbackRateMenu.isDisposable = value;
        this._miniPlayerMenu.isDisposable = value;
        this._nextCountdown.isDisposable = value;
        this._playerSegments.isDisposable = value;
    }
}